function fetchDomain() {
    const { hostname, port } = window.location;
    const parts = hostname.split('.');
    const subdomain = parts.shift();
    const rootHost = `${parts.join('.')}${port ? `:${port}` : ''}`;
    parts.unshift("app");
    let updatedHostname = parts.join('.');
    if (hostname.includes("localhost")) {
      updatedHostname += port ? `:${port}` : '';
    }
    const baseUrl = `//${updatedHostname}`;
  
    return {
      SUBDOMAIN: subdomain,
      HOST: updatedHostname,
      ROOT_HOST: rootHost,
      BASE_URL: baseUrl
    };
  }
  
  export const { SUBDOMAIN, HOST, ROOT_HOST, BASE_URL } = fetchDomain();