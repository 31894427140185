import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { List } from "@mui/material";
import "../css/WeblCreation/webl-creation.css";
import {
	checkPreprocessingStatus,
	editWebl,
	uploadWebl,
	updateVisibleArea,
	CheckCellIfVisibleArea,
	mergeRanges,
	CheckCellIfHiddenArea,
} from "../functions/WeblCreationFunctions.js";
import AvailableIcon from "../images/icons/available.svg";
import UnavailableIcon from "../images/icons/unavailable.svg";
import WeblHeader from "../components/ViewWebl/WeblHeader.jsx";
import FullPageLoading from "../components/FullPageLoading.jsx";
import ThreeButtons from "../components/WeblCreation/ThreeButtons.jsx";
import SelectedCells from "../components/WeblCreation/SelectedCells.jsx";
import Menu from "../components/WeblCreation/Menu.jsx";
import LuckysheetStyleMask from "../components/WeblCreation/LuckysheetStyleMask.jsx";
import AutoSelectionDialog from "../components/WeblCreation/AutoSelectionDialog";
import Inputs from "../components/WeblCreation/Inputs.jsx";
import ErrorDialog from "../components/ErrorDialog.jsx";

function WeblCreation() {
	const navigate = useNavigate(); // prettier-ignore
	const { user } = useAuth0(); // prettier-ignore
	const { state } = useLocation(); // prettier-ignore
	const weblidEdit = useParams(); // prettier-ignore
	// const directPublish = state !== null ? state.directPublish : null; // prettier-ignore // https://app.clickup.com/t/86bxryeyv
	const initialThemeSettingsLabels = { inputBorders: "Borders around input cells", tableSpacing: "Space between cells", gridlines: "Gridlines between cells", roundBorders: "Round corners around all cells", }; // prettier-ignore
	const sheetNamesDictionary = state !== null ? state.sheetNamesDictionary : weblidEdit["sheetNamesDictionary"]; // prettier-ignore
	const spreadsheetRef = useRef(null);
	const timeoutRefs = useRef([]); // prettier-ignore
	const [weblid, setWeblid] = useState(
		state !== null ? state.fileID : weblidEdit["weblid"]
	);
	const xlsxFile = state !== null ? state.xlsxFile : null; // prettier-ignore
	const [userType, setUserType] = useState("free");
	const [freeTierLimitations, setFreeTierLimitations] = useState({});
	const [centeredLayout, setCenteredLayout] = useState(false); // prettier-ignore
	const [chartData, setChartData] = useState();
	const [charts, setCharts] = useState(); // prettier-ignore
	const [createWeblButtonClicked, setCreateWeblButtonClicked] = useState(false); // prettier-ignore
	const [previewLoading, setPreviewLoading] = useState(false) // prettier-ignore
	const [currentSheet, setCurrentSheet] = useState(0); // prettier-ignore
	const [doNotShowAgainAutoselectionDialog, setDoNotShowAgainAutoselectionDialog, ] = useState(false); // prettier-ignore
	const [downloadableSpreadsheet, setDownloadableSpreadsheet] = useState(false); // prettier-ignore
	const [fitWidthSwitch, setFitWidthSwitch] = useState(true); // prettier-ignore
	const [hiddenRanges, setHiddenRanges] = useState([]); // prettier-ignore
	const [hideEmptySwitch, setHideEmptySwitch] = useState(true); // prettier-ignore
	const [zoomWebl, setZoomWebl] = useState(100); // prettier-ignore
	const [highlightSelectedClicked, setHighlightSelectedClicked] = useState(false); // prettier-ignore
	const [iframeDialogIsOpen, setIframeDialogIsOpen] = useState(false); // prettier-ignore
	const [iframeDimensions, setIframeDimensions] = useState({ width: "100%", height: "100%", }); // prettier-ignore
	const [initialThemeSettings, setInitialThemeSettings] = useState({ inputBorders: false, tableSpacing: false, roundBorders: false, gridlines: false, }); // prettier-ignore
	const [inputs, setInputs] = useState([]); // prettier-ignore
	const [openAutoselectionDialog, setOpenAutoselectionDialog] = useState(false); // prettier-ignore
	const [password, setPassword] = useState(null); // prettier-ignore
	const [passwordDialogIsOpen, setPasswordDialogIsOpen] = useState(false); // prettier-ignore
	const [passwordInputValue, setPasswordInputValue] = useState(0); // prettier-ignore
	const [passwordProtectEnabled, setPasswordProtectEnabled] = useState(false); // prettier-ignore
	const [passwordToShow, setPasswordToShow] = useState(null); // prettier-ignore
	const [peristentChanges, setPersistentChanges] = useState(false); // prettier-ignore
	const [customScrollbar, setCustomScrollbar] = useState(false); // prettier-ignore
	const [removeIframeCode, setRemoveIframeCode] = useState(false); // prettier-ignore
	const [processedSheets, setProcessedSheets] = useState([]);
	// const [readyForDirectPublish, setReadyForDirectPublish] = useState(false);// https://app.clickup.com/t/86bxryeyv
	const [readySpreadsheet, setReadySpreadsheet] = useState(false); // prettier-ignore
	const [realTimeSwitch, setRealTimeSwitch] = useState(false); // prettier-ignore
	const [removeWeblerLogo, setRemoveWeblerLogo] = useState(false);
	const [selectedTheme, setSelectedTheme] = useState({
		value: "initial",
		label: "Initial",
	}); // prettier-ignore
	const [selectedTab, setSelectedTab] = useState('Default'); // prettier-ignore
	const [selectSuggestions, setSelectSuggestions] = useState(false); // prettier-ignore
	const [sheetManager, setSheetManager] = useState(false);
	const [spreadsheet, setSpreadsheet] = useState(); // prettier-ignore
	const [suggestedInputs, setSuggestedInputs] = useState([]); // prettier-ignore
	const [suggestedVisibleArea, setSuggestedVisibleArea] = useState([]); // prettier-ignore
	const [visibleArea, setVisibleArea] = useState([]); // prettier-ignore
	const [weblTitle, setWeblTitle] = useState(); // prettier-ignore
	const [weblTitles, setWeblTitles] = useState(); // prettier-ignore
	const [weblDomain, setWeblDomain] = useState(useParams()['weblDomain']); // prettier-ignore
	const [weblDomains, setWeblDomains] = useState(); // prettier-ignore
	const [boxedContainer, setBoxedContainer] = useState(false); // prettier-ignore
	const [automatedHiddenRanges, setAutomatedHiddenRanges] = useState([]); // prettier-ignore
	const [automatedVisibleRanges, setAutomatedVisibleRanges] = useState([]); // prettier-ignore
	const [tabOpenStatus, setTabOpenStatus] = useState([
		false,
		false,
		false,
		false,
	]);
	const [typeOfSelection, setTypeOfSelection] = useState(null);
	const [errorDialog, setErrorDialog] = useState(null);

	const weblCreationMode =
		Object.entries(weblidEdit).length > 0 ? "update" : "create";

	const themeNames = [
		{ value: "initial", label: "Initial" },
		{ value: "theme_1", label: "Halite Blue" },
		{ value: "theme_2", label: "Alma" },
		{ value: "theme_3", label: "Burgundy" },
		{ value: "theme_4", label: "Salmon" },
		{ value: "theme_5", label: "Levander" },
		{ value: "theme_6", label: "Forest Green" },
		{ value: "theme_7", label: "CRT" },
		{ value: "theme_8", label: "Note" },
	];
	const tabNames = ["Default", "Tab1", "Tab2"];

	const subDirectories = [
		"weblcreation",
		"preview",
		"admin",
		"deletewebl",
		"getwebldata",
		"getuserwebls",
		"createwebl",
		"updatewebl",
		"updatepersistent",
		"uploadfile",
		"checkweblpassword",
		"getimage",
		"csrf",
		"getwebltitles",
		"getwebldomains",
		"getfileonedrivelink",
		"createusersettings",
		"editusersettings",
		"getusersettings",
		"createaddinwebl",
		"updateaddinwebl",
		"uploadaddinexcel",
		"sendaddinexcel",
		"checkpreprocessingstatus",
		"createweblthumbnail",
		"savedraft",
		"deleteweblpreview",
		"error",
		"stripe_webhooks",
		"deleteUser",
		"updateUserSettings",
		"updateAddinWebl",
		"createAddinWebl",
		"pricing",
		"saveAddinDraft",
		"getAddinUserLimits",
		"getUserLimits",
		"uploadFileApi",
	];

	/**
	 * Sets up a MutationObserver to monitor changes in the DOM elements of the spreadsheet container, and triggers
	 * the highlighting of selected elements (visible area or input) when the spreadsheet is ready. This effect is
	 * particularly useful for keeping track of the current sheet and handling changes in selected elements.
	 */
	useEffect(() => {
		// Using MutationObserver (a tool that observes changes in the DOM elements) - According to GPT that is worse performance than setInterval.
		if (readySpreadsheet) {
			const container = document.querySelector(
				".luckysheet-sheet-container-c"
			);

			const observerCallback = (mutationsList) => {
				for (const mutation of mutationsList) {
					if (
						mutation.type === "attributes" &&
						mutation.attributeName === "class"
					) {
						const newSheet = spreadsheet.getSheet()["order"];
						if (currentSheet !== newSheet)
							setCurrentSheet(newSheet);
					}
				}
			};

			const observerOptions = { attributes: true, subtree: true };
			const observer = new MutationObserver(observerCallback);
			observer.observe(container, observerOptions);
			const currentSheetNumber = parseInt(currentSheet);
			//If there are no hiddenRanges initially get the whole non visible area hidden
			// https://app.clickup.com/t/86bxryeyv
			// if (!directPublish) {
			let hiddenArea = Array.from(
				{ length: hiddenRanges.length },
				() => []
			);

			let mergedHiddenRanges = [];
			hiddenArea[currentSheet] = CheckCellIfVisibleArea(
				hiddenArea[currentSheet],
				spreadsheet,
				visibleArea,
				currentSheet
			);
			mergedHiddenRanges = mergeRanges(hiddenArea[currentSheet]);
			mergedHiddenRanges.forEach((range) => {
				hiddenRanges[currentSheet].push(range);
			});
			setAutomatedHiddenRanges(mergedHiddenRanges);

			let visible_area = Array.from(
				{ length: visibleArea.length },
				() => []
			);
			let mergedVisibleRanges = [];
			visible_area[currentSheet] = CheckCellIfHiddenArea(
				visible_area[currentSheet],
				spreadsheet,
				hiddenRanges,
				currentSheet
			);
			mergedVisibleRanges = mergeRanges(visible_area[currentSheet]);
			// mergedVisibleRanges.forEach((range) => {
			// 	visibleArea[currentSheet].push(range);
			// });
			setAutomatedVisibleRanges(mergedVisibleRanges);
			// }

			if (!processedSheets.includes(currentSheetNumber)) {
				const setCellFormat = spreadsheet.setCellFormat;
				const setCellValue = spreadsheet.setCellValue;
				const filteredChartData = chartData.filter(
					(data) => data.sheet_index === currentSheetNumber
				);
				for (const currentChartData of filteredChartData) {
					const startRow = currentChartData["location_start_row"];
					const startColumn =
						currentChartData["location_start_column"];

					const sheetIndex = currentChartData["sheet_index"];
					setCellValue(
						startRow,
						startColumn,
						currentChartData["type"],
						{
							order: sheetIndex,
						}
					);
					setCellFormat(startRow, startColumn, "bg", "#f5f5f5");
					setCellFormat(startRow, startColumn, "fc", "#e8666d");
					setCellFormat(startRow, startColumn, "it", 1);
					setCellFormat(startRow, startColumn, "ht", 0);
				}
				setProcessedSheets((prevProcessedSheets) => [
					...prevProcessedSheets,
					currentSheetNumber,
				]);
			}

			return () => observer.disconnect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readySpreadsheet, currentSheet, spreadsheet]);

	/**
	 * Initializes and configures the spreadsheet editor based on whether the component is mounted and whether
	 * an existing webl is being edited or a new webl is being uploaded. This effect is responsible for setting
	 * up various settings, importing dependencies, and managing component state. Additionally, it handles cleanup
	 * by clearing timeouts and marking the component as unmounted when the effect is cleaned up.
	 */
	useEffect(() => {
		const fetchData = async () => {
			let isMounted = true; // Add a variable to track the mounted state of the component

			const { default: LuckyExcel } = await import("luckyexcel");
			if (weblCreationMode === "update") {
				await editWebl(
					setWeblid,
					user.sub,
					isMounted,
					setCharts,
					setWeblTitles,
					setPasswordProtectEnabled,
					setSelectedTheme,
					setSelectedTab,
					setInitialThemeSettings,
					setIframeDimensions,
					setDownloadableSpreadsheet,
					setCenteredLayout,
					setPersistentChanges,
					setRealTimeSwitch,
					setFitWidthSwitch,
					setHideEmptySwitch,
					setSpreadsheet,
					setVisibleArea,
					setInputs,
					setHiddenRanges,
					setSuggestedVisibleArea,
					setSuggestedInputs,
					navigate,
					setChartData,
					LuckyExcel,
					setSelectSuggestions,
					setDoNotShowAgainAutoselectionDialog,
					setOpenAutoselectionDialog,
					themeNames,
					setWeblDomains,
					weblDomain,
					setZoomWebl,
					setBoxedContainer,
					setUserType,
					setRemoveWeblerLogo,
					setWeblTitle,
					setCustomScrollbar,
					setRemoveIframeCode
				);
				setReadySpreadsheet(true);
			} else if (weblCreationMode === "create") {
				let fileName = xlsxFile.name.replace(".xlsx", "");

				await uploadWebl(
					fileName,
					setWeblTitles,
					user.sub,
					LuckyExcel,
					xlsxFile,
					weblid,
					sheetNamesDictionary,
					isMounted,
					setCharts,
					setSpreadsheet,
					setSuggestedVisibleArea,
					setSuggestedInputs,
					setSelectSuggestions,
					setDoNotShowAgainAutoselectionDialog,
					setOpenAutoselectionDialog,
					setVisibleArea,
					setInputs,
					setHiddenRanges,
					navigate,
					setChartData,
					setWeblDomains,
					setUserType
				);
				await axios({
					method: "POST",
					url: "/getUserLimits/",
					data: {
						userid: user.sub,
						weblid: weblid,
					},
				})
					.then((res) => {
						setFreeTierLimitations(res.data);
						setReadySpreadsheet(true);
					})
					.catch((err) => {
						console.error(err);
					});

				let timeTaken =
					performance.now() - sessionStorage.getItem("startedTime");

				console.info(
					"Edit Webl - " +
						fileName +
						": " +
						Math.ceil(timeTaken / 1000) +
						"s"
				);
			}

			return () => {
				// timeoutRefs.current.forEach((timeoutId) =>
				// 	clearTimeout(timeoutId)
				// );
				// timeoutRefs.current = [];
				isMounted = false; // Mark the component as unmounted when the effect is cleaned up
			};
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (weblCreationMode === "update") {
			if (readySpreadsheet) {
				axios({
					method: "POST",
					url: "/getUserLimits/",
					data: {
						userid: user.sub,
						weblid: weblid,
					},
				})
					.then((res) => {
						setFreeTierLimitations(res.data);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readySpreadsheet]);

	/**
	 * Sets up a popstate event listener to handle changes in the browser's location. This effect is primarily used to
	 * clear any existing timeouts when the user navigates away from the current page to prevent potential issues
	 * related to ongoing asynchronous operations.
	 */
	useEffect(() => {
		const handleLocationChange = () => {
			timeoutRefs.current.forEach((timeoutId) => clearTimeout(timeoutId));
			timeoutRefs.current = [];
		};

		window.addEventListener("popstate", handleLocationChange);
		return () => {
			window.removeEventListener("popstate", handleLocationChange);
		};
	}, []);

	/**
	 * Monitors changes in the inputs and, when detected, determines and adds any input elements that are outside
	 * the visible area to the visible area. This effect ensures that input elements are included in the visible
	 * area when they fall outside it, enhancing the user's view of the spreadsheet.
	 */
	useEffect(() => {
		if (!sheetManager) {
			// updateVisibleArea(
			// 	inputs,
			// 	currentSheet,
			// 	visibleArea,
			// 	setVisibleArea
			// );
		} else {
			setSheetManager(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	/**
	 * Adds an input formula container to the spreadsheet area, which displays the formula of the selected cell.
	 * This effect sets up the container when the `spreadsheet` is available and removes it when the effect is cleaned up.
	 */
	useEffect(() => {
		if (spreadsheet) {
			const divElement = document.createElement("div");
			divElement.textContent = "";
			divElement.className = "input-formula-container";

			const sheetArea = document.querySelector(
				".luckysheet-sheet-area.luckysheet-noselected-text"
			);
			sheetArea.appendChild(divElement);

			// const luckysheetStatArea = document.getElementById(
			// 	"luckysheet-stat-area"
			// );
			// if (luckysheetStatArea)
			// 	luckysheetStatArea.parentNode.removeChild(luckysheetStatArea);

			return () => {
				sheetArea.removeChild(divElement);
			};
		}
	}, [spreadsheet]);

	/**
	 * Sets up an interval-based mechanism to monitor changes in the selected range within the spreadsheet.
	 * This effect updates the content of the input formula container based on the content of the selected cell's formula.
	 */
	useEffect(() => {
		if (spreadsheet && spreadsheet !== undefined) {
			const intervalId = setInterval(() => {
				const selectedRange = spreadsheet.getRange();
				const inputFormulaContainer = document.querySelectorAll(
					".input-formula-container"
				)[0];
				if (
					selectedRange[0]["column"][0] ===
						selectedRange[0]["column"][1] &&
					selectedRange[0]["row"][0] === selectedRange[0]["row"][1]
				) {
					const selectedCell = {
						row: selectedRange[0]["column"][0],
						column: selectedRange[0]["row"][0],
					};
					if (selectedCell.row >= 0 && selectedCell.column >= 0) {
						const sheetData = spreadsheet.getSheetData();
						if (sheetData && sheetData[selectedCell.column]) {
							const cellContent =
								sheetData[selectedCell.column][
									selectedCell.row
								];
							if (inputFormulaContainer) {
								if (
									cellContent &&
									cellContent.f !== undefined
								) {
									inputFormulaContainer.textContent =
										cellContent.f;
								} else {
									inputFormulaContainer.textContent = "";
								}
							}
						}
					}
					// const cellContent =
					// 	spreadsheet.getSheetData()[selectedCell.column][
					// 		selectedCell.row
					// 	];
					// if (inputFormulaContainer) {
					// 	if (cellContent && inputFormulaContainer) {
					// 		inputFormulaContainer.textContent = cellContent.f;
					// 	} else {
					// 		inputFormulaContainer.textContent = "";
					// 	}
					// }
				} else {
					inputFormulaContainer &&
						(inputFormulaContainer.textContent = "");
				}
			}, 500);
			return () => clearInterval(intervalId);
		}
	}, [spreadsheet]);

	// https://app.clickup.com/t/86bxryeyv
	/**
	 * Initiates the process of creating a webl with public access if `directPublish` is true and the spreadsheet is ready.
	 * It sets the `createWeblButtonClicked` state and triggers the `createWebl` function with public access.
	 */
	// useEffect(() => {
	// 	if (directPublish && readySpreadsheet) {
	// 		applySelectSuggesionsOption(true);

	// 		if (readyForDirectPublish) {
	// 			setCreateWeblButtonClicked(true);
	// 			createWebl({ publicAccess: "published" });
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [readySpreadsheet, directPublish, readyForDirectPublish]);

	/**
	 * Handles the user's choice to use suggested displays and inputs in the Luckysheet and updates relevant state variables.
	 */
	function applySelectSuggesionsOption(selectSuggestionsTmp) {
		const emptyArray = new Array(spreadsheet.getAllSheets().length)
			.fill()
			.map((element) => []);
		setVisibleArea(
			selectSuggestionsTmp
				? suggestedVisibleArea
				: structuredClone(emptyArray)
		);
		setInputs(
			selectSuggestionsTmp ? suggestedInputs : structuredClone(emptyArray)
		);
		// https://app.clickup.com/t/86bxryeyv
		// if (directPublish) {
		// 	setReadyForDirectPublish(true);
		// }
	}

	/**
	 * Initiates the process of saving a new or existing draft of the webl with the specified parameters, sends a request to the server,
	 * and handles various checks and responses including user alerts and error handling.
	 */
	const handleSaveDraft = () => {
		if (!weblDomain) {
			// alert(
			// 	"You must add a custom website path before publishing your Webl."
			// );
			setErrorDialog({
				reason: "You must add a custom website path before saving your Webl.",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
		} else if (weblDomains.includes(weblDomain)) {
			setErrorDialog({
				reason: "The domain " + weblDomain + " already exists.",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
		} else {
			axios({
				method: "POST",
				url: "/savedraft/",
				data: {
					weblid: weblid,
					title: weblTitle ? weblTitle.trim() : null,
					webl_domain: weblDomain,
					password_protect: passwordProtectEnabled,
					password: password,
					downloadable_spreadsheet: downloadableSpreadsheet,
					peristent_changes: peristentChanges,
					theme: selectedTheme.label,
					tab: selectedTab,
					initial_theme_settings:
						JSON.stringify(initialThemeSettings),
					centered_layout: centeredLayout,
					elements_range: JSON.stringify(visibleArea),
					input_range: JSON.stringify(inputs),
					hidden_range: JSON.stringify(hiddenRanges),
					suggested_elements_range:
						JSON.stringify(suggestedVisibleArea),
					suggested_input_range: JSON.stringify(suggestedInputs),
					webl_status: "draft",
					iframe_dimensions: JSON.stringify(iframeDimensions),
					real_time_switch: realTimeSwitch,
					fit_width: fitWidthSwitch,
					hide_empty: hideEmptySwitch,
					zoom_webl: zoomWebl,
					boxed_container: boxedContainer,
					remove_webler_logo: removeWeblerLogo,
					custom_scrollbar: customScrollbar,
					iframe_code: removeIframeCode,
				},
			})
				.then((resonse) => {
					setCreateWeblButtonClicked(false);
				})
				.catch((error) => {
					if (error.response) {
						// The client was given an error response (5xx, 4xx)
						console.error(
							error.response.status +
								" " +
								error.response.statusText
						);

						if (error.response.status === 404) {
							navigate("/error");
						} else {
							// Display a user-friendly error message for other errors
							alert("An error occurred. Please try again later.");
						}
					} else if (error.request) {
						// The client never received a response, and the request was never left
						console.error(error.request);
						alert(
							"An error occurred. Please check your internet connection."
						);
					} else {
						// Anything else
						console.error("error", error.message);

						if (error.statusCode === 404) {
							navigate("/error");
						} else {
							alert(
								"An unexpected error occurred. Please try again later."
							);
						}
					}
				});
		}
	};

	/**
	 * Initiates the process of creating a new webl with the specified parameters, sends a request to the server,
	 * and handles various checks and responses including user alerts and error handling.
	 */
	function createWebl(params) {
		console.log(removeIframeCode);
		sessionStorage.setItem("startedTime", performance.now());
		let visible_area_check = true;
		for (
			let sheetNumber = 0;
			sheetNumber < spreadsheet.getAllSheets().length;
			sheetNumber++
		) {
			//if visible area in the sheet is empty
			if (visibleArea[sheetNumber].length !== 0) {
				visible_area_check = false;
				break;
			}
		}
		if (visible_area_check) {
			setErrorDialog({
				reason: "Attention!",
				desc: `You must make at least one cell visible before
						${params.publicAccess === "preview" ? "previewing" : "publishing"}  your Webl.`,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (weblTitles.includes(weblTitle && weblTitle.trim())) {
			setErrorDialog({
				reason: "The webl " + weblTitle + " already exists.",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (!weblDomain) {
			setErrorDialog({
				reason: "You must add a custom website path before publishing your Webl.",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
		} else if (
			weblDomains
				.map((domain) => domain.toLowerCase())
				.includes(weblDomain.toLowerCase())
		) {
			setErrorDialog({
				reason: "The domain " + weblDomain + " already exists.",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (weblDomain.startsWith("-") || weblDomain.endsWith("-")) {
			setErrorDialog({
				reason: "Webl domain cannot start or end with '-'",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (weblDomain.includes("--")) {
			setErrorDialog({
				reason: "Webl domain cannot contain consequtive '-'",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (subDirectories.includes(weblDomain.toLocaleLowerCase())) {
			setErrorDialog({
				reason: "Webl domain cannot be a subdirectory",
				desc: null,
				btn_left: null,
				btn_right: "I understand",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else if (
			freeTierLimitations.can_create === false &&
			(params.publicAccess === "published" ||
				params.publicAccess === "iframe")
		) {
			setErrorDialog({
				desc: freeTierLimitations.desc,
				reason: freeTierLimitations.reason,
				btn_left: "Cancel",
				btn_right: "Upgrade my plan",
			});
			setCreateWeblButtonClicked(false);
			setPreviewLoading(false);
		} else {
			axios({
				method: "POST",
				url: "/createWebl/",
				data: {
					weblid: weblid,
					title: weblTitle ? weblTitle.trim() : null,
					webl_domain: weblDomain,
					password_protect: passwordProtectEnabled,
					password: password,
					downloadable_spreadsheet: downloadableSpreadsheet,
					peristent_changes: peristentChanges,
					theme: selectedTheme.label,
					tab: selectedTab,
					initial_theme_settings:
						JSON.stringify(initialThemeSettings),
					centered_layout: centeredLayout,
					elements_range: JSON.stringify(visibleArea),
					input_range: JSON.stringify(inputs),
					hidden_range: JSON.stringify(hiddenRanges),
					suggested_elements_range:
						JSON.stringify(suggestedVisibleArea),
					suggested_input_range: JSON.stringify(suggestedInputs),
					webl_status: params.publicAccess,
					iframe_dimensions: JSON.stringify(iframeDimensions),
					real_time_switch: realTimeSwitch,
					fit_width: fitWidthSwitch,
					hide_empty: hideEmptySwitch,
					images_data: {},
					zoom_webl: zoomWebl,
					boxed_container: boxedContainer,
					remove_webler_logo: removeWeblerLogo,
					custom_scrollbar: customScrollbar,
					iframe_code: removeIframeCode,
				},
			})
				.then((response) => {
					if (response.status === 202) {
						const taskId = response.data["task_id"];

						checkPreprocessingStatus(
							taskId,
							weblDomain,
							navigate,
							setPreviewLoading,
							params.publicAccess
						);
					}
				})
				.catch((error) => {
					if (error.response) {
						// The client was given an error response (5xx, 4xx)
						console.error(
							error.response.status +
								" " +
								error.response.statusText
						);

						if (error.response.status === 404) {
							navigate("/error");
						} else {
							// Display a user-friendly error message for other errors
							alert("An error occurred. Please try again later.");
						}
					} else if (error.request) {
						// The client never received a response, and the request was never left
						console.error(error.request);
						alert(
							"An error occurred. Please check your internet connection."
						);
					} else {
						// Anything else
						console.error("error", error.message);

						if (error.statusCode === 404) {
							navigate("/error");
						} else {
							alert(
								"An unexpected error occurred. Please try again later."
							);
						}
					}
				});
		}
	}

	/**
	 * Closes the auto-selection dialog and, if specified, updates user settings regarding the dialog's visibility and suggested defaults.
	 */
	const closeAutoSelectionDialog = () => {
		setOpenAutoselectionDialog(false);
		applySelectSuggesionsOption(selectSuggestions);

		axios.post("/editUserSettings/", {
			userid: user.sub,
			do_not_show_again: doNotShowAgainAutoselectionDialog,
			suggested_default: selectSuggestions,
		});
	};

	/**
	 * Closes the password dialog and resets the password input field value after a delay of 500 milliseconds.
	 */
	const closePasswordDialog = () => {
		setPasswordDialogIsOpen(false);
		setTimeout(() => {
			setPasswordInputValue("");
		}, 500);
	};

	return (
		<div>
			{!readySpreadsheet || createWeblButtonClicked ? (
				<FullPageLoading
					descTextLine1={"Loading in progress."}
					descTextLine2={
						"Loading time will vary depending on your file's complexity."
					}
				/>
			) : null}
			{previewLoading ? (
				<FullPageLoading
					descTextLine1={"Loading your webl preview."}
					descTextLine2={"You will be redirected to a new page."}
				/>
			) : null}
			<div
				style={{
					visibility: readySpreadsheet ? "visible" : "hidden",
					display: createWeblButtonClicked ? "none" : "initial",
				}}
			>
				<WeblHeader
					user={user}
					weblTitle={weblTitle}
					forWeblCreationPage={true}
				/>
				<div id="webl-creation-container">
					<div id="left-container">
						<Inputs
							weblTitle={weblTitle}
							setWeblTitle={setWeblTitle}
							weblTitles={weblTitles}
							UnavailableIcon={UnavailableIcon}
							AvailableIcon={AvailableIcon}
							weblDomain={weblDomain}
							setWeblDomain={setWeblDomain}
							weblDomains={weblDomains}
							subDirectories={subDirectories}
						/>
						<div id="webl-tabs-container">
							<Menu
								currentSheet={currentSheet}
								spreadsheet={spreadsheet}
								setVisibleArea={setVisibleArea}
								inputs={inputs}
								setInputs={setInputs}
								setHiddenRanges={setHiddenRanges}
								visibleArea={visibleArea}
								charts={charts}
								suggestedVisibleArea={suggestedVisibleArea}
								hiddenRanges={hiddenRanges}
								setHighlightSelectedClicked={
									setHighlightSelectedClicked
								}
								highlightSelectedClicked={
									highlightSelectedClicked
								}
								suggestedInputs={suggestedInputs}
								updateVisibleArea={updateVisibleArea}
								setSheetManager={setSheetManager}
								setRealTimeSwitch={setRealTimeSwitch}
								realTimeSwitch={realTimeSwitch}
								setCenteredLayout={setCenteredLayout}
								centeredLayout={centeredLayout}
								setPersistentChanges={setPersistentChanges}
								peristentChanges={peristentChanges}
								setDownloadableSpreadsheet={
									setDownloadableSpreadsheet
								}
								downloadableSpreadsheet={
									downloadableSpreadsheet
								}
								setFitWidthSwitch={setFitWidthSwitch}
								fitWidthSwitch={fitWidthSwitch}
								setHideEmptySwitch={setHideEmptySwitch}
								hideEmptySwitch={hideEmptySwitch}
								setOpenAutoselectionDialog={
									setOpenAutoselectionDialog
								}
								spreadsheetRef={spreadsheetRef}
								initialThemeSettings={initialThemeSettings}
								setInitialThemeSettings={
									setInitialThemeSettings
								}
								initialThemeSettingsLabels={
									initialThemeSettingsLabels
								}
								themeNames={themeNames}
								tabNames={tabNames}
								selectedTheme={selectedTheme}
								setSelectedTheme={setSelectedTheme}
								passwordProtectEnabled={passwordProtectEnabled}
								setPasswordDialogIsOpen={
									setPasswordDialogIsOpen
								}
								passwordDialogIsOpen={passwordDialogIsOpen}
								closePasswordDialog={closePasswordDialog}
								passwordToShow={passwordToShow}
								setPasswordInputValue={setPasswordInputValue}
								setPassword={setPassword}
								setPasswordProtectEnabled={
									setPasswordProtectEnabled
								}
								passwordInputValue={passwordInputValue}
								setPasswordToShow={setPasswordToShow}
								zoomWebl={zoomWebl}
								setZoomWebl={setZoomWebl}
								boxedContainer={boxedContainer}
								setBoxedContainer={setBoxedContainer}
								automatedHiddenRanges={automatedHiddenRanges}
								setAutomatedHiddenRanges={
									setAutomatedHiddenRanges
								}
								automatedVisibleRanges={automatedVisibleRanges}
								setAutomatedVisibleRanges={
									setAutomatedVisibleRanges
								}
								tabOpenStatus={tabOpenStatus}
								setTabOpenStatus={setTabOpenStatus}
								typeOfSelection={typeOfSelection}
								setTypeOfSelection={setTypeOfSelection}
								userType={userType}
								setErrorDialog={setErrorDialog}
								removeWeblerLogo={removeWeblerLogo}
								setRemoveWeblerLogo={setRemoveWeblerLogo}
								customScrollbar={customScrollbar}
								setCustomScrollbar={setCustomScrollbar}
								removeIframeCode={removeIframeCode}
								setRemoveIframeCode={setRemoveIframeCode}
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
						</div>
						<div
							className="spreadsheet-container"
							ref={spreadsheetRef}
							id="luckysheet"
							style={{
								display: readySpreadsheet
									? "inline-block"
									: "none",
								// display: "none",
							}}
						/>
						<LuckysheetStyleMask />
					</div>
					{readySpreadsheet && (
						<div id="buttons-selected-cells-display">
							<ThreeButtons
								setCreateWeblButtonClicked={
									setCreateWeblButtonClicked
								}
								setPreviewLoading={setPreviewLoading}
								createWebl={createWebl}
								setIframeDialogIsOpen={setIframeDialogIsOpen}
								iframeDialogIsOpen={iframeDialogIsOpen}
								weblid={weblid}
								iframeDimensions={iframeDimensions}
								setIframeDimensions={setIframeDimensions}
								weblCreationMode={weblCreationMode}
								handleSaveDraft={handleSaveDraft}
							/>

							<List id="selected-cells-display" dense={true}>
								<SelectedCells
									selectedArea={visibleArea}
									visibleArea={visibleArea}
									currentSheet={currentSheet}
									inputs={inputs}
									setInputs={setInputs}
									setVisibleArea={setVisibleArea}
									hiddenRanges={hiddenRanges}
									setHiddenRanges={setHiddenRanges}
									spreadsheet={spreadsheet}
									typeOfArea={"visibleArea"}
									TypographyText={"Visible Area"}
									typeOfSelection={typeOfSelection}
								/>
								<SelectedCells
									selectedArea={inputs}
									visibleArea={visibleArea}
									currentSheet={currentSheet}
									inputs={inputs}
									setInputs={setInputs}
									setVisibleArea={setVisibleArea}
									hiddenRanges={hiddenRanges}
									setHiddenRanges={setHiddenRanges}
									spreadsheet={spreadsheet}
									typeOfArea={"input"}
									TypographyText={"Inputs"}
									typeOfSelection={typeOfSelection}
								/>
								<SelectedCells
									selectedArea={hiddenRanges}
									visibleArea={visibleArea}
									currentSheet={currentSheet}
									inputs={inputs}
									setInputs={setInputs}
									setVisibleArea={setVisibleArea}
									hiddenRanges={hiddenRanges}
									setHiddenRanges={setHiddenRanges}
									spreadsheet={spreadsheet}
									typeOfArea={"hidden"}
									TypographyText={"Hidden Area"}
									typeOfSelection={typeOfSelection}
								/>
							</List>
						</div>
					)}
				</div>
			</div>
			{openAutoselectionDialog && (
				<AutoSelectionDialog
					selectSuggestions={selectSuggestions}
					setSelectSuggestions={setSelectSuggestions}
					doNotShowAgainAutoselectionDialog={
						doNotShowAgainAutoselectionDialog
					}
					setDoNotShowAgainAutoselectionDialog={
						setDoNotShowAgainAutoselectionDialog
					}
					closeAction={closeAutoSelectionDialog}
					cancelAction={() => setOpenAutoselectionDialog(false)}
				/>
			)}
			{errorDialog && (
				<ErrorDialog
					open={errorDialog !== null}
					onClose={() => setErrorDialog(null)}
					dialogText={errorDialog.reason}
					dialogText2={errorDialog.desc}
					confirmLabel={errorDialog.btn_right}
					cancelLabel={errorDialog.btn_left}
					confirmAction={() => {
						if (errorDialog.btn_right === "Upgrade my plan") {
							setErrorDialog(null);
							navigate("/pricing");
						} else setErrorDialog(null);
					}}
					cancelAction={() => {
						setErrorDialog(null);
					}}
				/>
			)}
		</div>
	);
}

export default withAuthenticationRequired(WeblCreation, {});
