import "../../css/Home/WeblControlPanel.css";
import { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog.jsx";
import DeleteIcon from "../../images/icons/delete.svg";
import EditIcon from "../../images/icons/edit.svg";
import LinkIcon from "../../images/icons/link.svg";
import Snackbar from "../Snackbar.jsx";
import ViewIcon from "../../images/icons/view.svg";
import ViewIconInactive from "../../images/icons/view_deactivated.svg";
import LinkIconInactive from "../../images/icons/link_deactivated.svg";
import { Scrollbars } from "rc-scrollbars";
import { Tooltip } from "@mui/material";
import { useDomain } from "../../context/DomainContext";

export default function WeblControlPanelList({
	weblList,
	sortWebls,
	sortWeblsBy,
	setSortWeblsBy,
	sortWeblsOrder,
	setSortWeblsOrder,
	deleteWeblCallback,
	weblToDelete,
	setWeblToDelete,
	setShowLoading,
}) {
	const [weblToCopyLink, setWeblToCopyLink] = useState(0);
	const { rootHost } = useDomain();

	return (
		<div id="webl-control-panel-table-container">
			<Scrollbars
				style={{
					width: "100%",
				}}
				hideTracksWhenNotNeeded={true}
			>
				<table>
					<thead>
						<tr>
							<th className="file-name-column">
								<div
									onClick={() => {
										setSortWeblsBy("fileName");
										setSortWeblsOrder(
											sortWeblsOrder === "desc"
												? "asc"
												: "desc"
										);
									}}
								>
									<div>File name</div>
									<div
										style={{
											visibility:
												sortWeblsBy === "fileName"
													? "visible"
													: "hidden",
										}}
									>
										{sortWeblsOrder === "desc" ? "▼" : "▲"}
									</div>
								</div>
							</th>
							<th className="date-column">
								<div
									onClick={() => {
										setSortWeblsBy("date");
										setSortWeblsOrder(
											sortWeblsOrder === "desc"
												? "asc"
												: "desc"
										);
									}}
								>
									<div>Date</div>
									<div
										style={{
											visibility:
												sortWeblsBy === "date"
													? "visible"
													: "hidden",
										}}
									>
										{sortWeblsOrder === "desc" ? "▼" : "▲"}
									</div>
								</div>
							</th>
							<th className="actions-column">
								<div>Actions</div>
							</th>
							<th className="status-column">
								<div>Status</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{sortWebls(weblList, sortWeblsBy, sortWeblsOrder).map(
							(weblInfo, index) => (
								<tr key={"row-" + weblInfo[0]}>
									<td className="file-name-column">
										<div>
											<span className="file-name-number">
												{index + 1}.
											</span>
											{weblInfo[5]}
										</div>
									</td>
									<td className="date-column">
										<div>{weblInfo[2].split(" ")[0]}</div>
									</td>
									<td className="actions-column">
										<div>
										<Tooltip title="view webl">
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={"//" + weblInfo[5] + "." + rootHost}
												onClick={
													weblInfo[4] !== "draft"
														? null
														: (e) =>
																e.preventDefault()
												}
											>
												<img
													src={
														weblInfo[4] !== "draft"
															? ViewIcon
															: ViewIconInactive
													}
													alt="view icon"
												/>
											</a>
											</Tooltip>
											<Tooltip title="copy url to clipboard">
											<img
												src={
													weblInfo[4] === "published"
														? LinkIcon
														: LinkIconInactive
												}
												onClick={() => {
													if (
														weblInfo[4] ===
														"published"
													) {
														navigator.clipboard.writeText(
															weblInfo[5] + "." + rootHost
														);
														setWeblToCopyLink(
															weblInfo[1]
														);
													}
												}}
												alt="link icon"
											/>
											</Tooltip>

											<Snackbar
												open={
													weblToCopyLink ===
													weblInfo[1]
												}
												autoHideDuration={2000}
												onClose={() =>
													setWeblToCopyLink(0)
												}
												message="Webl URL was copied to clipboard!"
											/>
											<Tooltip title = "Edit webl">
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={
													"/updateWebl/" + weblInfo[5]
												}
											>
												<img
													src={EditIcon}
													alt="edit icon"
												/>
											</a>
											</Tooltip>
											<Tooltip title="delete webl">
											<img
												src={DeleteIcon}
												alt="delete icon"
												onClick={() =>
													setWeblToDelete(weblInfo[1])
												}
											/>
											</Tooltip>
											<ConfirmationDialog
												open={
													weblToDelete === weblInfo[1]
												}
												onClose={() =>
													setWeblToDelete(0)
												}
												dialogText="This action is going to delete permanently this Webl. Are you sure you want to continue?"
												confirmAction={() => {
													setShowLoading(true);
													setTimeout(
														() =>
															setShowLoading(
																false
															),
														2000
													);
													deleteWeblCallback();
												}}
												cancelAction={() =>
													setWeblToDelete(0)
												}
											/>
										</div>
									</td>
									<td className="status-column">
										<div
											style={{
												textTransform: "capitalize",
												fontStyle:
													weblInfo[4] === "draft"
														? "italic"
														: "normal",
											}}
										>
											{weblInfo[4]}
										</div>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</Scrollbars>
		</div>
	);
}
