import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { ReactComponent as CustomCheckedIcon } from "../../images/icons/check_button.svg";
import { ReactComponent as CustomUncheckedIcon } from "../../images/icons/uncheck_button.svg";
import { useState } from "react";
import { Scrollbars } from "rc-scrollbars";

export default function UploaderMultipleSheetsDialog({
	sheetNames,
	confirmLabel = "Continue",
	confirmAction,
	cancelLabel = "Cancel",
	cancelAction,
}) {
	const [isCheckAll, setIsCheckAll] = useState(true);
	const [sheetsToChoose, setSheetsToChoose] = useState(sheetNames);

	return (
		<div
			className="custom-dialog"
			onClick={(e) =>
				e.target.className === "custom-dialog" && cancelAction()
			}
		>
			<div
				className="custom-dialog-content"
				id="webl-uploader-multiple-sheets-dialog"
			>
				<div>Your file contains multiple sheets.</div>
				<div>
					Confirm which sheets you would like to upload to Webler:
				</div>
				<FormControl size="small" fullWidth>
					<InputLabel id="multiple-sheet-label">
						Sheet Names
					</InputLabel>
					<Select
						labelId="multiple-sheet-label"
						id="webl-uploader-multiple-sheets-select"
						multiple
						value={sheetsToChoose}
						input={<OutlinedInput label="Sheet Names" />}
						renderValue={(selected) => selected.join(", ")}
						MenuProps={{
							PaperProps: {
								sx: { maxHeight: "40vh", width: 200 },
							},
						}}
					>
						<Scrollbars
							style={{
								width: "500px",
							}}
							autoHeight
							hideTracksWhenNotNeeded={true}
						>
							<MenuItem
								key="Select All"
								value="Select All"
								className="webl-uploader-multiple-sheets-select-value"
								onClick={() => {
									if (isCheckAll) {
										setSheetsToChoose([]);
									} else {
										setSheetsToChoose(sheetNames);
									}
									setIsCheckAll(!isCheckAll);
								}}
								style={{
									borderBottom: "1px solid black",
								}}
								disableRipple
								disableFocusRipple
								disableTouchRipple
							>
								<Checkbox
									icon={
										<CustomUncheckedIcon
											style={{ width: 24, height: 24 }}
										/>
									}
									checkedIcon={
										<CustomCheckedIcon
											style={{ width: 24, height: 24 }}
										/>
									}
									checked={isCheckAll}
									onChange={{}}
								/>
								<i>
									<ListItemText primary="Select All" />
								</i>
							</MenuItem>
							{sheetNames.map((sheetName) => (
								<MenuItem
									key={sheetName}
									value={sheetName}
									className="webl-uploader-multiple-sheets-select-value"
									onClick={() => {
										if (
											sheetsToChoose.includes(sheetName)
										) {
											// If item is already in the list, remove it
											const updatedItems =
												sheetsToChoose.filter(
													(item) => item !== sheetName
												);
											setSheetsToChoose(updatedItems);
										} else {
											// If item is not in the list, add it
											const updatedItems = [
												...sheetsToChoose,
												sheetName,
											];
											setSheetsToChoose(updatedItems);
										}
									}}
									disableRipple
									disableFocusRipple
									disableTouchRipple
								>
									<Checkbox
										icon={
											<CustomUncheckedIcon
												style={{
													width: 24,
													height: 24,
												}}
											/>
										}
										checkedIcon={
											<CustomCheckedIcon
												style={{
													width: 24,
													height: 24,
												}}
											/>
										}
										checked={
											sheetsToChoose.indexOf(sheetName) >
											-1
										}
										onChange={{}}
									/>
									{sheetName}
								</MenuItem>
							))}
						</Scrollbars>
					</Select>
				</FormControl>
				<div className="confirmation-buttons">
					<button className="cancel_btn" onClick={cancelAction}>
						{cancelLabel}
					</button>
					<button
						className="confirm_btn"
						onClick={() => confirmAction(sheetsToChoose)}
					>
						{confirmLabel}
					</button>
				</div>
			</div>
		</div>
	);
}
