import Axios from "axios";
const FormData = require('form-data');


const microserviceURL = process.env.REACT_APP_MICROSERVICE_URL

const axiosConfig = () => {
	let axios = Axios.create()
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		axios.defaults.baseURL = 'http://localhost:5000'
	} else {
		axios.defaults.baseURL = microserviceURL
	}
	return axios	
}

const microserviceAxios = axiosConfig()


export const deleteSheets = async (fileId, sheetNames, navigate) => {
	try {
		const response =await microserviceAxios.post('/get_session', {
			sheetNames: sheetNames,
			fileId: fileId
		})

		if (response.status === 200){
			return 
		} else if (response.status === 404) {
			navigate("/error")
		} else {
			alert("An error occurred. Please try again later.")
		}
		
	} catch (error) {
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}

};

export const readFile = async (fileId, navigate) => {
	try {
		const response = await microserviceAxios.get(
			"/read_file/" +
			fileId)
		if (response.status === 200) {
			return response.data;
		}else if (response.status === 404) {
			navigate("/error")
		} else {
			alert("An error occurred. Please try again later.")
		}

	} catch (error) {
		console.log(error)
		alert("An unexpected error occurred. Please try again later.");
		}
};

export const uploadFile = async (
	fileName,
	mediaContent,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary
) => {
	const reactAppFolder = process.env.REACT_APP_MODEL_FOLDER
	try {
		const formData = new FormData() // Create a form with the file
		formData.append('file', mediaContent)
		formData.append('fileName', fileName);
		formData.append('userFolder', userFolder);
		formData.append('reactAppFolder', reactAppFolder);

		const response = await microserviceAxios
			.post('/upload_file',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
	)
		if (response.status === 200) {
			const responseSheets = response.data.responseSheets
			const uploadFileResponse = response.data.uploadFileResponse
			
			const sheetNamesDictionary = {};
			responseSheets.value.forEach((worksheet) => {
				sheetNamesDictionary[worksheet.name] = worksheet.id;
			});
			setSheetNamesDictionary(sheetNamesDictionary);
			setFileID(uploadFileResponse.id);
			setFile(mediaContent);
			setShowLoading(true);
			return true;
		} else { 
			throw new Error()
		}
	} catch (error) {
		console.log(error)
		alert("An unexpected error occurred. Please try again later.");
	}
		
};

export const downloadFile = async (sessionInfo, fileId, navigate) => {
	try {
		const response = await microserviceAxios.post('/get_session', {
			fileId: fileId,
			sessionInfo: sessionInfo
		})
		if(response.status === 200){
			const url = window.URL.createObjectURL(
				new Blob([response], { type: "application/octet-stream" })
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "Webl.xlsx");
			document.body.appendChild(link);
			link.click();
		}
		else if (response.status === 404) {
			navigate("/error");
		} else {
			alert("An error occurred. Please try again later.");
		}
	} catch (error) {
		// Anything else
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}
};

export const deleteFile = async (fileId, navigate) => {
	try {
		const response = await microserviceAxios.delete(
			"/delete_file/" +
			fileId)
		
		if (response.status === 200) {
			console.log('File deleted')
		} else if (response.status === 404) {
			navigate("/error");
		} else {
			throw new Error()
		}
	} catch (error) {
		// Anything else
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}
}

export const createSession = async (
	setSessionInfo,
	fileId,
	peristentChanges
) => {
	try {
		const response = await microserviceAxios.post('/get_session', {
			persistentChanges: peristentChanges,
			fileId: fileId
		})
		const sessionId = response.data.sessionId
		setSessionInfo(sessionId);
	} catch (error) {
		console.error(error);
	}
};

export const readFormat = async (
	sessionInfo,
	setCellFormat,
	sheetId,
	range) => {
	try {
		const response = await microserviceAxios
			.post('/read_format', {
				sheetId: sheetId,
				sessionInfo: sessionInfo,
				range: range
			})
		if (response.status === 200) {
			setCellFormat(response.data.numberFormat);
		}
	} catch (error) {
		// Anything else
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}
};

export const setCellValue = async (
	sessionInfo,
	fileId,
	sheetId,
	row,
	column,
	value
) => {
	const data = { values: [[value]], };
	try {
		const response = await microserviceAxios
			.post('/set_cell_value', {
				sheetId: sheetId,
				sessionInfo: sessionInfo,
				column: column,
				row: row,
				fileId: fileId,
				values: data
			})
		
		if (response.status === 200) {
			console.log('Values modified');
		}
	} catch (error) {
		// Anything else
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}
};

export const getCellValue = async (
	sessionInfo,
	fileId,
	setCellValues,
	sheetId,
	row,
	column
) => {
	const response = await microserviceAxios
			.post('/get_cell_value', {
				sheetId: sheetId,
				sessionInfo: sessionInfo,
				column: column,
				row: row,
				fileId: fileId,
			})

	setCellValues(response.data.text);
};

export const setRangeValue = async (
	sessionInfo,
	fileId,
	sheetId,
	range,
	value
) => {
	const data = {
		values: [[value]],
	};
	try {
		await microserviceAxios
			.post('/set_range_value', {
				sheetId: sheetId,
				sessionInfo: sessionInfo,
				range: range,
				value: data,
				fileId: fileId,
			})
	} catch (error) {
			// Anything else
			console.error("error", error.message);
			alert("An unexpected error occurred. Please try again later.");
	}
	
};

export const getRangeValue = async (sessionInfo, fileId, sheetId, range) => {
	const response = await microserviceAxios
		.post('/get_range_value', {
			sheetId: sheetId,
			sessionInfo: sessionInfo,
			range: range,
			fileId: fileId,
	})
	return response.data.text;
};

export const keepSessionAlive = async (sessionInfo, fileId, sheetId) => {
	try {
		const response = await microserviceAxios
			.post('/keep_session_alive', {
				fileId: fileId,
				sheetId: sheetId,
				sessionInfo:sessionInfo
			})

	} catch (error) {
		console.error(error);
	}
};

export const closeSession = async (sessionInfo, fileId) => {
	try {
		const response = await microserviceAxios
			.post('/close_session', {
				fileId: fileId,
				sessionInfo:sessionInfo
			})

	} catch (error) {
		console.error(error);
	}
};

export const getChartNames = async (sessionInfo, fileId, sheetId) => {
	const response = await microserviceAxios
		.post('/get_chart_names', {
			sessionInfo: sessionInfo,
			sheetId: sheetId,
			fileId: fileId,
		})
	
	return response.data.charts.value.map((chart) => chart.name);
};

export const getChartImage = async (
	sessionInfo,
	fileId,
	sheetId,
	chartName
) => {
	const response = await microserviceAxios
		.post('/get_chart_image', {
			sessionInfo: sessionInfo,
			sheetId: sheetId,
			fileId: fileId,
			chartName: chartName
		})
	return response.data.value;
	// await new Promise((resolve) => setTimeout(resolve, 100)); // Add a delay of 1 second
};

export const getChart = async (
	sessionInfo,
	fileId,
	setChart,
	sheetId,
	chartName
) => {
	const response = await microserviceAxios
		.post('/get_chart', {
			sessionInfo: sessionInfo,
			sheetId: sheetId,
			fileId: fileId,
			chartName: chartName
		})

	setChart(response.data.value);
};
