import "../css/LoadingAnimation.css";

export default function LoadingAnimation({ descTextLine1, descTextLine2 }) {
	return (
		<div className="loading-container">
			<div className="loading-inner">
				<div className="loading-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<div className="loading-text">
				<strong>{descTextLine1}</strong>
				<br />
				{descTextLine2}
			</div>
		</div>
	);
}
