import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from "./AppProvider";
import App from "./components/App";
import "./css/index.css";
import "./fonts/Arial Nova/ArialNova.ttf";
import "./fonts/Arial Nova/ArialNova-Bold.ttf";
import "./fonts/Arial Nova/ArialNova-Italic.ttf";

ReactDOM.render(
	<AppProvider>
		<App />
	</AppProvider>,
	document.getElementById("root")
);
