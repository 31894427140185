import { useState, useRef, useEffect } from "react";
import DeselectAllIcon from "../../images/tool icons/WeblCreation/deselect_all.svg";
import SelectAllIcon from "../../images/tool icons/WeblCreation/selected_area.svg";
import ApplySuggestionsIcon from "../../images/tool icons/WeblCreation/apply_suggestions.svg";
import "../../css/WeblCreation/dropdown.css";
import "../../css/WeblCreation/style.css";
import { Scrollbars } from "rc-scrollbars";
import { Tooltip } from "@mui/material";
import Tabs from "../../images/webl_creation_topbar/Tabs.svg";
import InitialTheme from "../../images/icons/Theme Icons/Icon_Initial.svg";
import IconTheme1 from "../../images/icons/Theme Icons/Icon_theme_01.svg";
import IconTheme2 from "../../images/icons/Theme Icons/Icon_theme_02.svg";

function Dropdown({
	sheetNames,
	inputs,
	suggestedInputs,
	setInputs,
	updateVisibleArea,
	setSheetManager,
	selectAllElements,
	spreadsheet,
	visibleArea,
	charts,
	setVisibleArea,
	suggestedVisibleArea,
	resetElementSelections,
	hiddenRanges,
	setHiddenRanges,
	tabNames,
	selectedTab,
	setSelectedTab,
}) {
	useEffect(() => {
		if (selectedTab) setTabToChoose(selectedTab);
	}, [selectedTab]);
	const [tabToChoose, setTabToChoose] = useState(
		selectedTab ? selectedTab : tabNames[0]
	);
	const [open, setOpen] = useState(false);
	const tableRef = useRef(null);
	const buttonRef = useRef(null);
	const tabContainerRef = useRef(null);
	const handleOpen = () => {
		setOpen(!open);
	};

	const applySuggestions = (e, index) => {
		e.preventDefault();
		let inputsCopy = structuredClone(inputs);
		if (suggestedInputs.length > 0) {
			inputsCopy[index] = suggestedInputs[index];
		}

		if (inputsCopy[index] !== null) {
			setSheetManager(true);
			spreadsheet.setRangeShow(inputsCopy[index], {
				order: index,
				show: true,
			});
			setInputs(inputsCopy);
			updateVisibleArea(inputsCopy, index, visibleArea, setVisibleArea);
		}
	};

	const handleClick = (e) => {
		if (
			tableRef.current &&
			!tableRef.current.contains(e.target) &&
			open &&
			buttonRef.current &&
			!buttonRef.current.contains(e.target)
		) {
			handleOpen();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);
	const handleSelectTab = (tab) => {
		const oldSelectionElement = document.getElementById(tabToChoose);
		const newSelectionElement = document.getElementById(tab);
		oldSelectionElement.style.border = "3px solid transparent";
		newSelectionElement.style.border = "3px solid var(--webl_lightgreen)";
		setSelectedTab(tab);

		setTabToChoose(tab);
	};
	const IconThemes = [InitialTheme, IconTheme1, IconTheme2];
	return (
		<>
			<button
				ref={buttonRef}
				className="basic-button"
				onClick={handleOpen}
				style={{ display: "flex", alignItems: "center", gap: "10px" }}
			>
				<img style={{ width: "30px" }} src={Tabs}></img>
				<span style={{ color: "white" }}>Tabs</span>
			</button>
			{open ? (
				<div ref={tableRef} id="sheet-manager-table-container">
					<table id="sheet-manager-table">
						<thead id="table-header">
							<tr>
								<th>Sheet Name</th>
								<th>
									<span id="actions-span">
										Display Actions
									</span>
								</th>
								<th>
									<span id="actions-span">
										Inputs Actions
									</span>
								</th>
							</tr>
						</thead>
						<Scrollbars
							style={{
								width: "100%",
							}}
							hideTracksWhenNotNeeded={true}
							autoHeight
						>
							{sheetNames.map((sheetName, index) => (
								<tr
									className="table-row"
									key={"table-row-" + sheetName}
								>
									<td>
										<span className="sheet-number">
											{index + 1}
										</span>
										.
										<span className="sheet-name">
											{sheetName}
										</span>
									</td>
									<td>
										<Tooltip
											title="Select all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													setVisibleArea(
														selectAllElements(
															"visibleArea",
															index,
															structuredClone(
																visibleArea
															),
															spreadsheet,
															visibleArea,
															suggestedVisibleArea,
															charts,
															inputs
														)
													);
												}}
											>
												<img
													src={SelectAllIcon}
													alt="Select all"
												/>
											</div>
										</Tooltip>
										<Tooltip
											title="Deselect all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													resetElementSelections(
														visibleArea,
														setVisibleArea,
														index
													);
													resetElementSelections(
														inputs,
														setInputs,
														index
													);
													resetElementSelections(
														hiddenRanges,
														setHiddenRanges,
														index
													);
												}}
											>
												<img
													src={DeselectAllIcon}
													alt="Deselect all"
												/>
											</div>
										</Tooltip>
									</td>
									<td>
										<Tooltip
											title="Select all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													setInputs(
														selectAllElements(
															"input",
															index,
															structuredClone(
																inputs
															),
															spreadsheet,
															visibleArea,
															suggestedInputs,
															charts,
															inputs
														)
													);
												}}
											>
												<img
													src={SelectAllIcon}
													alt="Select all"
												/>
											</div>
										</Tooltip>
										<Tooltip
											title="Deselect all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													let inputsCopy =
														structuredClone(inputs);
													inputsCopy[index] = [];
													setInputs(inputsCopy);
												}}
											>
												<img
													src={DeselectAllIcon}
													alt="Deselect all"
												/>
											</div>
										</Tooltip>
										<Tooltip
											title="Apply suggestions"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												size="small"
												disableRipple
												disableFocusRipple
												disableTouchRipple
												onClick={(event) =>
													applySuggestions(
														event,
														index
													)
												}
											>
												<img
													src={ApplySuggestionsIcon}
													alt="Apply suggestions"
												/>
											</div>
										</Tooltip>
									</td>
								</tr>
							))}
						</Scrollbars>
					</table>
					<div id="tabs-container" ref={tabContainerRef}>
						<div id="tabs-left">
							<div id="title-container">Tabs</div>
							<div id="themes-footnote-container">
								<p>
									<strong>Note:</strong>
								</p>
								<p>
									The selected tab theme will only be applied
									to your published Webl.
								</p>
							</div>
						</div>
						<div id="tabs-right">
							<div id="themes-list">
								{tabNames.map((tab, index) => (
									<div key={"theme-" + tab}>
										<img
											id={tab}
											src={IconThemes[index]}
											alt={tab}
											onClick={() => handleSelectTab(tab)}
											className={
												"theme-icon " + tabToChoose &&
												tabToChoose === tab
													? "selected-theme"
													: ""
											}
										/>
										<div className="theme-title">{tab}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}

export default Dropdown;
