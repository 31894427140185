import { useState } from "react";
import { downloadFile } from "../../functions/spreadsheetFunctions";
import ConfirmationDialog from "../ConfirmationDialog";
import Snackbar from "../Snackbar.jsx";
import DeleteWebl from "../../images/icons/ViewWebl/delete.svg";
import EditWebl from "../../images/icons/ViewWebl/edit.svg";
import CopyEmbedCode from "../../images/icons/ViewWebl/get_code_copy.svg";
import CopyLink from "../../images/icons/ViewWebl/link.svg";
import DownloadUnavailable from "../../images/icons/ViewWebl/download_unavailable.svg";
import DownloadAvailable from "../../images/icons/ViewWebl/download_available.svg";
import HamburgerIcon from "../../images/icons/hamburger.svg";

import "../../css/Webl/WeblManager.css";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
function WeblManager({
	sheetNames,
	sheetsVisibility,
	sheetToShow,
	setWeblValuesReady,
	setSheetToShow,
	downloadableSpreadsheet,
	sessionInfo,
	weblid,
	weblTitle,
	currentUserIsTheOwner,
	handleEditWebl,
	setWeblToDelete,
	weblToDelete,
	deleteWebl,
	navigate,
	iframeComponent,
	previewMode,
	removeIframeCode,
}) {
	const [embedCodeCopied, setEmbedCodeCopied] = useState(false);
	const [linkCopied, setLinkCopied] = useState(false);
	const [openSheetTabsModal, setOpenSheetTabsModal] = useState(false);

	const scrollSheetTabs = (direction) => {
		const container = document.getElementById("sheetTabsContainer");
		const scrollAmount = 150;

		const onScrollEnd = () => {
			const scrollableWidth =
				container.scrollWidth - container.clientWidth;

			// Check if there is more content to scroll left and hide/show the left arrow button
			const leftArrowButton = document.querySelector(
				".sheet-arrow-button-left"
			);
			if (container.scrollLeft === 0) {
				leftArrowButton.style.visibility = "hidden";
			} else {
				leftArrowButton.style.visibility = "visible";
			}

			// Check if there is more content to scroll right and hide/show the right arrow button
			const rightArrowButton = document.querySelector(
				".sheet-arrow-button-right"
			);

			if (Math.round(container.scrollLeft) >= scrollableWidth) {
				rightArrowButton.style.visibility = "hidden";
			} else {
				rightArrowButton.style.visibility = "visible";
			}
		};

		if (direction === "left") {
			container.scrollTo({
				left: container.scrollLeft - scrollAmount,
				behavior: "smooth",
			});
		} else {
			container.scrollTo({
				left: container.scrollLeft + scrollAmount,
				behavior: "smooth",
			});
		}

		container.addEventListener("scroll", onScrollEnd);
	};

	useEffect(() => {
		const handleResize = () => {
			const container = document.getElementById("sheetTabsContainer");

			const scrollableWidth =
				container.scrollWidth - container.clientWidth;
			const leftArrowButton = document.querySelector(
				".sheet-arrow-button-left"
			);
			if (container.scrollLeft === 0) {
				leftArrowButton.style.visibility = "hidden";
			} else {
				leftArrowButton.style.visibility = "visible";
			}

			const rightArrowButton = document.querySelector(
				".sheet-arrow-button-right"
			);
			if (Math.round(container.scrollLeft) + 1 >= scrollableWidth) {
				rightArrowButton.style.visibility = "hidden";
			} else {
				rightArrowButton.style.visibility = "visible";
			}
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<div className="webl-manager-container">
			<div id="webl-manager-left-item" />
			<div id="webl-manager-inner-container">
				<div className="sheet-tabs-container" id="sheetTabsContainer">
					{sheetNames.map((sheet, index) => {
						if (sheet && sheetsVisibility[index]) {
							const isSelected = sheetToShow === index;

							return (
								<div
									value={index}
									key={"sheet-tab" + sheet}
									className={
										isSelected
											? "sheet-tab sheet-tab-selected"
											: "sheet-tab"
									}
									onClick={() => {
										if (!isSelected) {
											setWeblValuesReady(false);
											setSheetToShow(index);
										}
									}}
								>
									{sheetNames[index]}
								</div>
							);
						}
						return null;
					})}
				</div>
				{openSheetTabsModal && (
					<div className="sheet-tabs-modal">
						{sheetNames.map((sheet, index) => {
							if (sheet && sheetsVisibility[index]) {
								const isSelected = sheetToShow === index;

								return (
									<div
										value={index}
										key={"sheet-tab" + sheet}
										className={
											isSelected
												? "sheet-tab sheet-tab-selected"
												: "sheet-tab"
										}
										onClick={() => {
											if (!isSelected) {
												setWeblValuesReady(false);
												setSheetToShow(index);
											}
										}}
									>
										{sheetNames[index]}
									</div>
								);
							}
							return null;
						})}
					</div>
				)}
				<div
					className="sheet-arrow-button sheet-arrow-button-left"
					onClick={() => scrollSheetTabs("left")}
				>
					◀
				</div>
				<div
					className="sheet-arrow-button sheet-arrow-button-right"
					onClick={() => scrollSheetTabs("right")}
				>
					▶
				</div>
				<div id="sheet-hamburger-menu-container">
					<button
						id="hamburger_button"
						onClick={() => {
							setOpenSheetTabsModal(!openSheetTabsModal);
						}}
					>
						<img src={HamburgerIcon} alt="Copy-Webl-Link" />
					</button>
				</div>
				{previewMode === false && (
					<div id="spreadsheet-actions">
						{removeIframeCode !== null &&
							removeIframeCode === false && (
								<>
									<Tooltip title="Copy the code to embed your Webl in an external webpage">
										<button
											id="embed_button"
											onClick={() => {
												navigator.clipboard.writeText(
													iframeComponent
												);
												setEmbedCodeCopied(true);
											}}
										>
											<img
												src={CopyEmbedCode}
												alt="Copy-Embed-Code"
											/>
										</button>
									</Tooltip>
									<Snackbar
										open={embedCodeCopied}
										autoHideDuration={2000}
										onClose={() =>
											setEmbedCodeCopied(false)
										}
										message="Embed code was copied to clipboard!"
									/>
								</>
							)}

						<Tooltip
							title={
								downloadableSpreadsheet
									? "Download the original spreadsheet"
									: "The Webl's owner has disabled download of the original spreadsheet"
							}
						>
							<button
								id="download_button"
								key="download_button"
								onClick={() =>
									downloadableSpreadsheet &&
									downloadFile(sessionInfo, weblid, navigate)
								}
								style={{
									cursor: downloadableSpreadsheet
										? "pointer"
										: "auto",
								}}
							>
								{
									downloadableSpreadsheet ? (
										<img
											src={DownloadAvailable}
											alt="Download-Webl"
										/>
									) : null
									// <img
									// 	src={DownloadUnavailable}
									// 	alt="Download-Webl"
									// />
								}
							</button>
						</Tooltip>

						{currentUserIsTheOwner && (
							<>
								<Tooltip title="Edit your Webl">
									<button
										id="custom-edit_button"
										key="edit_button"
										onClick={handleEditWebl}
									>
										<img src={EditWebl} alt="Edit-Webl" />
									</button>
								</Tooltip>
								<Tooltip title="Delete your Webl">
									<button
										id="custom-delete_button"
										key="delete_button"
										onClick={() => setWeblToDelete(weblid)}
									>
										<img
											src={DeleteWebl}
											alt="Delete-Webl"
										/>
									</button>
								</Tooltip>
							</>
						)}
						<ConfirmationDialog
							open={weblToDelete === weblid}
							onClose={() => setWeblToDelete(0)}
							dialogText="This action is going to delete permanently this Webl. Are you sure you want to continue?"
							confirmAction={() => {
								deleteWebl();
								setTimeout(() => navigate("/"), 2000);
							}}
							cancelAction={() => setWeblToDelete(0)}
						/>
					</div>
				)}
			</div>
			<div id="webl-manager-right-item" />
		</div>
	);
}

export default WeblManager;
