import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { DomainProvider } from "./context/DomainContext";
import { Auth0Provider } from "@auth0/auth0-react";

export function AppProvider({children}) {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    
    const THEME = createTheme({
        typography: {
            fontFamily: `"Arial Nova", sans-serif`,
        },
        palette: {
            primary: {
                main: "#343739",
            },
            secondary: {
                main: "#395643",
            },
        },
    });

    return (
        <DomainProvider>
            <ThemeProvider theme={THEME}>
                <React.StrictMode>
                    <Auth0Provider
                        domain={domain}
                        clientId={clientId}
                        useRefreshTokens={true}
                        cacheLocation="localstorage"
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                        }}
                    >
                        {children}
                    </Auth0Provider>
                </React.StrictMode>
            </ThemeProvider>
        </DomainProvider>
    )
}