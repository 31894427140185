import { useEffect, useState, useRef } from "react";
import "../../css/home.css";
import "../../css/Home/WeblUploader.css";
import {
	handleFileSelection,
	importXLSX,
	// launchOneDriveFilePicker,
} from "../../functions/HomeFunctions";
import {
	uploadFile,
	deleteSheets,
	readFile,
} from "../../functions/spreadsheetFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSwitch from "../../components/CustomSwitch.jsx";
import FullPageLoading from "../../components/FullPageLoading.jsx";
import OnedriveLinkField from "./OnedriveLinkField.jsx";
import UploaderMultipleSheetsDialog from "./UploaderMultipleSheetsDialog.jsx";
import UploadIcon from "../../images/icons/upload.svg";
import UploadHoverIcon from "../../images/icons/upload_hover.svg";
import AutomaticIdentificationDialog from "./AutomaticIdentificationDialog.jsx";

export default function WeblUploader() {
	const { user } = useAuth0();
	const navigate = useNavigate();
	const [addinUpload, setAddinUpload] = useState(false);
	// const [directPublishWebl, setDirectPublishWebl] = useState(false); // https://app.clickup.com/t/86bxryeyv
	const [file, setFile] = useState();
	const [fileID, setFileID] = useState();
	const [fileName, setFileName] = useState();
	// const [onedriveAccountIsBusiness, setOnedriveBusinessAccount] = useState(false); // prettier-ignore
	const [openOnedriveLinkField, setOpenOnedriveLinkField] = useState(false);
	const [sheetNames, setSheetNames] = useState([]);
	const [sheetNamesDictionary, setSheetNamesDictionary] = useState([]);
	const [sheetSelectDialogIsOpen, setSheetSelectDialogIsOpen] = useState(false); // prettier-ignore
	const [showLoading, setShowLoading] = useState(false);
	const [uploadUploadImageSrc, setUploadImageSrc] = useState(UploadIcon);
	const [
		automaticIdentificationDialogShow,
		setAutomaticIdentificationDialogShow,
	] = useState(false); // prettier-ignore // https://app.clickup.com/t/86bxryeyv
	const fileInputRef = useRef(null);
	const userFolder = user.sub.split("|")[1];

	/**
	 * fetches data when 'fileID', 'file', 'fileName', or 'navigate' change.
	 * If 'fileID', 'file', and 'fileName' are provided and the file's name matches 'fileName',
	 * it reads the file, processes it using the 'importXLSX' function, and updates the state
	 * with the sheet names from the workbook.
	 */
	useEffect(() => {
		const fetchData = async () => {
			if (fileID && file && fileName) {
				if (file.name === fileName) {
					const xlsx = await importXLSX();
					const reader = new FileReader();
					reader.onload = (e) => {
						const data = new Uint8Array(e.target.result);
						const sheetnames = xlsx.read(data, {
							type: "array",
						}).SheetNames;

						setSheetNames(sheetnames);
					};
					reader.readAsArrayBuffer(file);
				}
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileID, file, fileName, navigate]);

	/**
	 * Handles the add-in-a app GET method.
	 * It retrieves file information from the URL parameters, makes an Axios GET request
	 * to fetch a file based on a unique key, processes the response into a File object,
	 * and uploads it using the 'uploadFile' function. It also handles error responses
	 * and may navigate the user to an error page if necessary.
	 */
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		let fileName = urlParams.get("file");
		const uniqueKey = urlParams.get("key");
		if (uniqueKey && addinUpload) {
			axios
				.get("/sendAddinExcel/" + uniqueKey, {
					responseType: "blob", // Set the response type to 'blob'
				})
				.then((response) => {
					const blob = new Blob([response.data], {
						type: "application/octet-stream",
					});
					// Create a File-like object to simulate a file upload
					const file = new File([blob], fileName, {
						type: "application/octet-stream",
					});
					// Now you have the "file" object, which can be used like a file uploaded from an input element
					setAddinUpload(false);
					setFileName(file["name"]);
					uploadFile(
						file["name"],
						file,
						userFolder,
						setFileID,
						setFile,
						setShowLoading,
						setSheetNamesDictionary
					);
				})
				.catch((error) => {
					setAddinUpload(false);
					if (error.response) {
						// The client was given an error response (5xx, 4xx)
						console.error(
							error.response.status +
								" " +
								error.response.statusText
						);

						if (error.response.status === 404) {
							navigate("/error");
						} else {
							// Display a user-friendly error message for other errors
							alert("An error occurred. Please try again later.");
						}
					} else if (error.request) {
						// The client never received a response, and the request was never left
						console.error(error.request);
						alert(
							"An error occurred. Please check your internet connection."
						);
					} else {
						// Anything else
						console.error("error", error.message);
						alert(
							"An unexpected error occurred. Please try again later."
						);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addinUpload]);

	/**
	 * Manages the loading state and UI based on the 'sheetNames' array.
	 * If there are multiple sheet names available, it opens a dialog for selecting a sheet.
	 * If there is only one sheet, it initiates the file upload to Webl creation.
	 * If there are no sheet names, it handles the loading state accordingly.
	 */
	useEffect(() => {
		if (sheetNames.length > 1) {
			setShowLoading(false);
			setSheetSelectDialogIsOpen(true);
		} else if (sheetNames.length === 1) {
			setShowLoading(false);
			handleUploadFileToWeblCreation();
		} else {
			setShowLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sheetNames]);

	// https://app.clickup.com/t/86bxryeyv
	/**
	 * Resets the 'directPublishWebl' state to 'false'.
	 * This effect runs only once, during component initialization, to ensure 'directPublishWebl' is initially set to 'false'.
	 */
	// useEffect(() => {
	// 	setDirectPublishWebl(false);
	// }, []);

	function cancelFileSelection() {
		setFile();
		setFileID();
		setFileName();
		setSheetNames([]);
		setSheetNamesDictionary([]);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	}

	/**
	 * Takes the selected sheets from the file the user selected and redirects the user to the WeblCreation page
	 */
	const handleUploadFileToWeblCreation = async (
		sheetsToChoose = sheetNames
	) => {
		let dialogFlag = false;
		if (sheetsToChoose || sheetNames.length === 1) {
			if (sheetsToChoose && sheetsToChoose.length === 0) {
				alert("You must choose at least one sheet");
			} else {
				sessionStorage.setItem("startedTime", performance.now());
				setShowLoading(true);
				let sheetsToDelete = 0;
				if (sheetNames.length !== 1) {
					try {
						sheetsToDelete = sheetNames.filter(
							(sheet) => !sheetsToChoose.includes(sheet)
						);

						await deleteSheets(fileID, sheetsToDelete, navigate);
					} catch (error) {
						if (error === "File not found") {
							alert("File not found. Navigating back to Home.");
							setShowLoading(false);
							setSheetNames([]);
							dialogFlag = true;
							setSheetSelectDialogIsOpen(false);
							navigate("/");
						}
					}
				}
				try {
					const fileResponse = await readFile(fileID, navigate);
					const downloadURL =
						fileResponse.data["@microsoft.graph.downloadUrl"];
					axios
						.get(downloadURL, { responseType: "blob" })
						.then((fileResponse) => {
							const downloadedFile = new File(
								[fileResponse.data],
								file.name,
								{
									type: file.type,
									lastModified: file.lastModified,
								}
							);
							window.sessionStorage.setItem(
								"loadWeblCreation",
								true
							);
							navigate("/weblCreation/", {
								state: {
									xlsxFile: downloadedFile,
									fileID: fileID,
									fileName: fileName
										.replace(".xlsx", "")
										.replace(".csv", ""),
									sheetNamesDictionary: sheetNamesDictionary,
									// directPublish: directPublishWebl, // https://app.clickup.com/t/86bxryeyv
								},
							});
						})
						.catch((error) => {
							console.error(error);
						});
				} catch (error) {
					if (dialogFlag === false) {
						setShowLoading(false);
						setSheetNames([]);
						setSheetSelectDialogIsOpen(false);
						navigate("/error");
					} else {
						setShowLoading(false);

						if (error.response) {
							// The client was given an error response (5xx, 4xx)
							console.error(
								error.response.status +
									" " +
									error.response.statusText
							);

							if (error.response.status === 404) {
								navigate("/error");
							} else {
								// Display a user-friendly error message for other errors
								alert(
									"An error occurred. Please try again later."
								);
							}
						} else if (error.request) {
							// The client never received a response, and the request was never left
							console.error(error.request);
							alert(
								"An error occurred. Please check your internet connection."
							);
						} else {
							// Anything else
							console.error("error", error.message);
							alert(
								"An unexpected error occurred. Please try again later."
							);
						}
					}
				}
			}
		}
		dialogFlag = false;
	};

	return (
		<div id="webl-uploader-container">
			{showLoading && (
				<FullPageLoading
					descTextLine1={"Upload in progress."}
					descTextLine2={
						"Upload time will vary depending on your file's complexity."
					}
				/>
			)}
			<div id="webl-uploader-text-container">
				<div className="custom-title">Create a Webl</div>
				<div className="custom-subtitle">
					Upload your spreadsheet below or share a OneDrive link
				</div>
			</div>

			{sheetSelectDialogIsOpen && (
				<UploaderMultipleSheetsDialog
					sheetNames={sheetNames}
					confirmAction={(sheetsToChoose) => {
						setShowLoading(false);
						setSheetSelectDialogIsOpen(false);
						handleUploadFileToWeblCreation(sheetsToChoose);
					}}
					cancelAction={() => {
						cancelFileSelection();
						setSheetSelectDialogIsOpen(false);
					}}
				/>
			)}
			<div
				id="webl-uploader-main-container"
				style={{ borderRadius: 18 }}
				className="rounded-borders"
			>
				<div id="webl-uploader-buttons-container">
					<div id="webl-uploader-main-button-container">
						<div id="upload-spreadsheet-container">
							<label
								htmlFor="upload-file-input"
								className="home-button rounded-borders"
								id="upload-spreadsheet-button"
								onMouseEnter={() => {
									setUploadImageSrc(UploadHoverIcon);
								}}
								onMouseLeave={() => {
									setUploadImageSrc(UploadIcon);
								}}
							>
								<img
									src={uploadUploadImageSrc}
									alt="Upload Icon"
								/>
								Upload spreadsheet
							</label>
							<input
								ref={fileInputRef}
								style={{ display: "none" }}
								onChange={(e) => {
									handleFileSelection(
										e,
										setShowLoading,
										setFileName,
										userFolder,
										setFileID,
										setFile,
										setSheetNamesDictionary
									);
								}}
								id="upload-file-input"
								name="upload-file-input"
								type="file"
								accept={".xlsx, .csv"}
							/>
						</div>
					</div>
					<div id="webl-uploader-other-buttons-container">
						{/* <div id="onedrive-picker-container">
							<button
								className="home-button rounded-borders"
								onClick={(e) =>
									launchOneDriveFilePicker(
										e,
										setFileName,
										userFolder,
										setFileID,
										setFile,
										setShowLoading,
										setSheetNamesDictionary,
										onedriveAccountIsBusiness,
										navigate
									)
								}
							>
								Open OneDrive
							</button>
							<div className="webl-uploader-other-buttons-text">
								<span
									onClick={() =>
										setOnedriveBusinessAccount(
											!onedriveAccountIsBusiness
										)
									}
								>
									Business Account
								</span>
								<CustomSwitch
									checked={onedriveAccountIsBusiness}
									onChange={() =>
										setOnedriveBusinessAccount(
											!onedriveAccountIsBusiness
										)
									}
									height={16}
									width={40}
									backgroundColorChecked={"#8cc63f"}
									translate={18}
								/>
							</div>
						</div> */}
						<div id="onedrive-link-container">
							<button
								className="home-button rounded-borders"
								onClick={() => setOpenOnedriveLinkField(true)}
							>
								Add a OneDrive link
							</button>
							{/* <div className="webl-uploader-other-buttons-text">
								<span>Paste your link into the field</span>
							</div> */}
						</div>
						{openOnedriveLinkField && (
							<OnedriveLinkField
								setShowLoading={setShowLoading}
								setFileName={setFileName}
								userFolder={userFolder}
								setFileID={setFileID}
								setFile={setFile}
								setSheetNamesDictionary={
									setSheetNamesDictionary
								}
								navigate={navigate}
								closeFieldFunction={() =>
									setOpenOnedriveLinkField(false)
								}
							/>
						)}
					</div>
				</div>
				{/* https://app.clickup.com/t/86bxryeyv */}
				{/* <div id="webl-upload-method-container">
					<span onClick={() => setDirectPublishWebl(false)}>
						<span className="webl-upload-method-hide-text">
							Upload and{" "}
						</span>
						edit
					</span>
					<CustomSwitch
						checked={directPublishWebl}
						onChange={() =>
							setDirectPublishWebl(!directPublishWebl)
						}
						height={16}
						width={40}
						backgroundColorChecked={"#8cc63f"}
						translate={18}
					/>
					<span onClick={() => setDirectPublishWebl(true)}>
						<span className="webl-upload-method-hide-text">
							Upload and{" "}
						</span>
						publish
					</span>
				</div> */}
			</div>

			{/* https://app.clickup.com/t/86bxryeyv */}
			{/* <div id="webl-uploader-helptext-container">
				<span>How to</span>{" "}
				<span
					onClick={() => {
						setAutomaticIdentificationDialogShow(true);
					}}
				>
					Click to discover how to style your spreadsheet for
					automatic identification of your components.
				</span>
			</div>
			{automaticIdentificationDialogShow && (
				<AutomaticIdentificationDialog
					closeAction={() =>
						setAutomaticIdentificationDialogShow(false)
					}
				/>
			)} */}
		</div>
	);
}
