import WeblerLogo from "../../images/Logo/SVG/Webler_Original.svg";
import LogoutButton from "../../components/LogoutButton.jsx";
import CornerIcon from "../../images/icons/corner.svg";
// import { useEffect } from "react";
import { useDomain } from "../../context/DomainContext";

import "../../css/Webl/WeblHeader.css";
function WeblHeader({
	user,
	weblTitle,
	forWeblCreationPage = false,
	removeWeblerLogo,
}) {
	const { baseUrl } = useDomain();

	return (
		<div id="webl-header-container">
			<div id="webl-header">
				<div id="webl-header-left-border"> </div>
				<div id="webl-header-inner-container">
					<a
						id="webl-header-left-item"
						href={baseUrl}
						rel="noreferrer"
					>
						{removeWeblerLogo ? null : (
							<img src={WeblerLogo} alt="Webler Logo" />
						)}
					</a>
					{user && (
						<div id="webl-header-right-item">
							<LogoutButton />
						</div>
					)}
				</div>
				<div id="webl-header-right-border"> </div>
			</div>
			{!forWeblCreationPage && (
				<div id="webl-outline-top">
					<div id="webl-outline-top-left">
						<img src={CornerIcon} alt="corner-icon" />
					</div>
					<div id="webl-outline-title">
						{weblTitle && <span>{weblTitle}</span>}
					</div>
					<div id="webl-outline-top-right" />
				</div>
			)}
		</div>
	);
}

export default WeblHeader;
