import {
	convertColumnNumberToLetter,
	removeRangeFromElementSelections,
	uniqueCombinations,
	resetElementSelections,
} from "../../functions/WeblCreationFunctions.js";
import "../../css/WeblCreation/selected-cells.css";
import DeleteIcon from "../../images/tool icons/WeblCreation/delete.svg";
import Scrollbars from "rc-scrollbars";
import { useEffect } from "react";

function SelectedCells({
	selectedArea,
	visibleArea,
	currentSheet,
	inputs,
	setInputs,
	setVisibleArea,
	hiddenRanges,
	setHiddenRanges,
	spreadsheet,
	typeOfArea,
	TypographyText,
	typeOfSelection,
}) {
	useEffect(() => {
		uniqueCombinations(hiddenRanges);
		uniqueCombinations(inputs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="selected-area-container">
			<p variant="button" className="header-selected-area">
				<strong>{TypographyText}</strong>
			</p>
			<hr />
			<button
				style={{
					backgroundColor: "#395643",
					color: "white",
					border: "none",
					padding: "5px",
					fontSize: "13px",
				}}
				onClick={() => {
					if (TypographyText === "Visible Area") {
						resetElementSelections(
							visibleArea,
							setVisibleArea,
							currentSheet
						);
					} else if (TypographyText === "Inputs") {
						resetElementSelections(inputs, setInputs, currentSheet);
					} else {
						resetElementSelections(
							hiddenRanges,
							setHiddenRanges,
							currentSheet
						);
					}
				}}
			>
				Clear All
			</button>
			<div className="side-panel-wrapper">
				<Scrollbars
					autoHeightMax={"76px"}
					autoHeight={true}
					hideTracksWhenNotNeeded={true}
				>
					{selectedArea ? (
						selectedArea[currentSheet].length !== 0 ? (
							selectedArea[currentSheet].map((range, i) => {
								if (range) {
									if (
										range.column[0] === range.column[1] &&
										range.row[0] === range.row[1]
									) {
										return (
											<div
												className="side-panel-item"
												onMouseLeave={() => {
													if (
														typeOfArea ===
															"input" &&
														typeOfSelection ===
															"input"
													) {
														spreadsheet.setRangeShow(
															[
																...inputs[
																	currentSheet
																],
															],
															{ show: true }
														);
													} else {
														spreadsheet.setRangeShow(
															"A2",
															{ show: false }
														);
													}
												}}
												onMouseEnter={() =>
													spreadsheet.setRangeShow(
														range
													)
												}
												key={typeOfArea + "-" + i}
											>
												<div>
													{convertColumnNumberToLetter(
														range.column[0] + 1
													) +
														(range.row[0] + 1)}
												</div>
												<div
													className="x-container"
													onClick={() => {
														removeRangeFromElementSelections(
															typeOfArea,
															range,
															currentSheet,
															spreadsheet,
															visibleArea,
															setVisibleArea,
															inputs,
															setInputs,
															hiddenRanges,
															setHiddenRanges
														);
													}}
												>
													<img
														className="delete-icon"
														src={DeleteIcon}
														alt="Delete Icon"
													/>
												</div>
											</div>
										);
									} else {
										return (
											<div
												className="side-panel-item"
												onMouseLeave={() => {
													if (
														typeOfArea ===
															"visibleArea" &&
														typeOfSelection ===
															"visibleArea"
													) {
														spreadsheet.setRangeShow(
															[
																...visibleArea[
																	currentSheet
																],
															],
															{ show: true }
														);
													} else if (
														typeOfArea ===
															"hidden" &&
														typeOfSelection ===
															"hidden"
													) {
														spreadsheet.setRangeShow(
															[
																...hiddenRanges[
																	currentSheet
																],
															],
															{ show: true }
														);
													} else {
														spreadsheet.setRangeShow(
															"A2",
															{ show: false }
														);
													}
												}}
												onMouseEnter={() =>
													spreadsheet.setRangeShow(
														range
													)
												}
												key={typeOfArea + "-" + i}
											>
												<div>
													{convertColumnNumberToLetter(
														range.column[0] + 1
													) +
														(range.row[0] + 1) +
														":" +
														convertColumnNumberToLetter(
															range.column[1] + 1
														) +
														(range.row[1] + 1)}
												</div>
												<div
													className="x-container"
													onClick={() => {
														removeRangeFromElementSelections(
															typeOfArea,
															range,
															currentSheet,
															spreadsheet,
															selectedArea,
															setVisibleArea,
															inputs,
															setInputs,
															hiddenRanges,
															setHiddenRanges
														);
													}}
												>
													<img
														className="delete-icon"
														src={DeleteIcon}
														alt="Delete Icon"
													/>
												</div>
											</div>
										);
									}
								} else {
									return (
										<div>
											<p className="no-select-caption">
												<i>no area selected</i>
											</p>
										</div>
									);
								}
							})
						) : (
							<div>
								<p className="no-select-caption">
									<i>no area selected</i>
								</p>
							</div>
						)
					) : (
						<div>
							<p className="no-select-caption">
								<i>no area selected</i>
							</p>
						</div>
					)}
				</Scrollbars>
			</div>
		</div>
	);
}

export default SelectedCells;
