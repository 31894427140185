import { useState, useRef, useEffect } from "react";
import "../../css/WeblCreation/settings.css";
import CustomSwitch from "../CustomSwitch";
import InfoSvg from "../../images/tool icons/WeblCreation/information.svg";
import { Tooltip, Slider } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Features from "../../images/webl_creation_topbar/Features.svg";

function Settings({
	realTimeSwitch,
	centeredLayout,
	peristentChanges,
	downloadableSpreadsheet,
	fitWidthSwitch,
	hideEmptySwitch,
	passwordProtectEnabled,
	hideOrUnhideMenu,
	handleChangeRealTime,
	handleContextAwareCropping,
	handlePersistentContentChanges,
	handleDownloadableSpreadsheet,
	handleExpandColumnWidth,
	handleExcludeEmptyHiddenRows,
	handleOpenUserSettings,
	handlePasswordProtect,
	setZoomWebl,
	zoomWebl,
	handleBoxedContainer,
	boxedContainer,
	handleRemoveWeblerLogo,
	removeWeblerLogo,
	handleCustomScrollbar,
	customScrollbar,
	handleRemoveIframe,
	removeIframeCode,
	userType,
	setErrorDialog,
}) {
	const [open, setOpen] = useState(false);
	const settingsContainerRef = useRef(null);
	const buttonRef = useRef(null);

	const handleZoomChange = (_, newValue) => {
		setZoomWebl(newValue);
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClick = (e) => {
		const dialogOpen = document.querySelector(".custom-dialog");
		if (
			settingsContainerRef.current &&
			!settingsContainerRef.current.contains(e.target) &&
			open &&
			buttonRef.current &&
			!buttonRef.current.contains(e.target) &&
			e.target.className !== "custom-dialog" &&
			e.target.id !== "pass_btn" &&
			(!dialogOpen || (dialogOpen && !dialogOpen.contains(e.target)))
		) {
			setOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<div id="settings">
			<button
				ref={buttonRef}
				className="basic-button"
				onClick={(event) => {
					hideOrUnhideMenu(event, 3);
					handleOpen(event);
				}}
				style={{ display: "flex", alignItems: "center", gap: "10px" }}
			>
				<img style={{ width: "30px" }} src={Features}></img>
				<span style={{ color: "white" }}>Features</span>
			</button>
			{open ? (
				<div id="settings-container" ref={settingsContainerRef}>
					<div
						className="switch-container"
						style={{ borderBottom: "none" }}
					>
						<span id="first-row" className="span-first-level">
							Features
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={passwordProtectEnabled}
								onChange={() => {
									if (userType === "free") {
										setErrorDialog({
											reason: "Upgrade your plan!",
											desc: "Upgrade to Pro plan in order to protect your Webls with password!",
											btn_left: "Cancel",
											btn_right: "Upgrade my plan",
										});
									} else {
										handlePasswordProtect();
									}
								}}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={() => {
									if (userType === "free") {
										setErrorDialog({
											reason: "Upgrade your plan!",
											desc: "Upgrade to Pro plan in order to protect your Webls with password!",
											btn_left: "Cancel",
											btn_right: "Upgrade my plan",
										});
									} else {
										handlePasswordProtect();
									}
								}}
							>
								Add a password to access your Webl
							</span>
							<Tooltip
								title="Available as part of the pro plan. Secure your Webl with a password to prevent unrestricted access."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={realTimeSwitch}
								onChange={handleChangeRealTime}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleChangeRealTime}
							>
								Enable real-time updates
							</span>
							<Tooltip
								title="Enable this setting to enable automatic updates of your Webl as a user types in input cells, instead of them needing to press Enter."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={centeredLayout}
								onChange={handleContextAwareCropping}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleContextAwareCropping}
							>
								Enable context-aware cropping
							</span>
							<Tooltip
								title="Automatically remove excess space and centre your content."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={peristentChanges}
								onChange={handlePersistentContentChanges}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handlePersistentContentChanges}
							>
								Enable persistent content changes across users
							</span>
							<Tooltip
								title="User inputs on your Webl will be automatically saved and will persist across users."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={downloadableSpreadsheet}
								onChange={handleDownloadableSpreadsheet}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleDownloadableSpreadsheet}
							>
								Enable users to download your spreadsheet
							</span>
							<Tooltip
								title="The spreadsheet you uploaded to Webler will be available to download, including the cells you made hidden."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={fitWidthSwitch}
								onChange={handleExpandColumnWidth}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleExpandColumnWidth}
							>
								Expand column width to fit text
							</span>
							<Tooltip
								title="Expand the width of columns to fit the text within them."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={hideEmptySwitch}
								onChange={handleExcludeEmptyHiddenRows}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleExcludeEmptyHiddenRows}
							>
								Remove hidden / empty cells from your final view
							</span>
							<Tooltip
								title="Remove the rows and columns you mark as hidden or that are empty from your final Webl."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={boxedContainer}
								onChange={handleBoxedContainer}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={handleBoxedContainer}
							>
								Apply a box around your Webl
							</span>
							<Tooltip
								title="Apply a box around your published Webl instead of it sitting natively in the browser."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
						{boxedContainer && (
							<span
								className="span-first-level"
								style={{ paddingLeft: "40px" }}
							>
								<CustomSwitch
									checked={customScrollbar}
									onChange={handleCustomScrollbar}
									height={13}
									width={32}
									backgroundColorChecked={"#8cc63f"}
									translate={13}
								/>
								<span
									className="span-second-level"
									onClick={handleCustomScrollbar}
								>
									Apply custom scrollbar
								</span>
								<Tooltip
									title="Enable custom scrollbars on the final webl inside the boxed container."
									arrow
									placement="right"
									className="info-tooltip"
								>
									<img
										className="svg-icon"
										src={InfoSvg}
										alt="Info Icon"
									/>
								</Tooltip>
							</span>
						)}
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={removeWeblerLogo}
								onChange={() => {
									if (userType !== "pro_plus") {
										setErrorDialog({
											reason: "Upgrade your plan!",
											desc: "Upgrade to Pro Plus plan in order remove the Webler logo from your final Webl!",
											btn_left: "Cancel",
											btn_right: "Upgrade my plan",
										});
									} else {
										handleRemoveWeblerLogo();
									}
								}}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={() => {
									if (userType !== "pro_plus") {
										setErrorDialog({
											reason: "Upgrade your plan!",
											desc: "Upgrade to Pro Plus plan in order to remove the Webler logo from your final Webl!",
											btn_left: "Cancel",
											btn_right: "Upgrade my plan",
										});
									} else {
										handleRemoveWeblerLogo();
									}
								}}
							>
								Remove the Webler logo from your Webl
							</span>
							<Tooltip
								title="Available as part of the Pro Plus plan. Remove the Webler logo from your published Webl."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div className="switch-container">
						<span className="span-first-level">
							<CustomSwitch
								checked={removeIframeCode}
								onChange={() => {
									handleRemoveIframe();
								}}
								height={13}
								width={32}
								backgroundColorChecked={"#8cc63f"}
								translate={13}
							/>
							<span
								className="span-second-level"
								onClick={() => {
									handleRemoveIframe();
								}}
							>
								Remove copy iframe code button from your Webl
							</span>
							<Tooltip
								title="If enabled, it removes the copy iframe code button from your final Webl."
								arrow
								placement="right"
								className="info-tooltip"
							>
								<img
									className="svg-icon"
									src={InfoSvg}
									alt="Info Icon"
								/>
							</Tooltip>
						</span>
					</div>
					<div id="second-row" className="switch-container">
						<span className="span-first-level">
							Change the Webl zoom level:
						</span>
					</div>
					<div className="switch-container zoom-slider-container">
						<span
							className="span-first-level"
							style={{ width: "70%" }}
						>
							<RemoveIcon
								onClick={() => {
									zoomWebl >= 20 &&
										setZoomWebl(zoomWebl - 10);
								}}
								style={{ cursor: "pointer" }}
								size="small"
							/>
							<Slider
								value={zoomWebl}
								onChange={handleZoomChange}
								min={10}
								max={200}
								step={10}
								size="small"
								marks={[{ value: 100 }]}
								sx={{
									"& .MuiSlider-thumb": {
										width: 4,
										borderRadius: 0,
										color: "var(--webl_lightgreen)",

										"&:before": {
											boxShadow:
												"0 4px 8px rgba(0,0,0,0.4)",
										},
										"&:hover, &.Mui-focusVisible, &.Mui-active":
											{
												boxShadow: "none",
											},
									},
								}}
							/>
							<AddIcon
								onClick={() => {
									zoomWebl <= 190 &&
										setZoomWebl(zoomWebl + 10);
								}}
								style={{ cursor: "pointer" }}
								size="small"
							/>
							<div className="zoom-value-container">
								{zoomWebl}%
							</div>
						</span>
					</div>
					{/* <div
						onClick={handleOpenUserSettings}
						id="open-user-settings"
						className="switch-container"
					>
						<span
							className="span-first-level"
							style={{ textDecoration: "underline" }}
						>
							Click to open user settings
						</span>
					</div> */}
				</div>
			) : null}
		</div>
	);
}

export default Settings;
