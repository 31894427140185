import { useState, useEffect } from "react";
import {
	markAreaAsElement,
	selectAllElements,
	highlightElementSelections,
	resetElementSelections,
	CheckCellIfVisibleArea,
	mergeRanges,
	removeRangeFromElementSelections,
} from "../../functions/WeblCreationFunctions.js";
import Dropdown from "./Dropdown.jsx";
import Settings from "./Settings.jsx";
import Style from "./Style.jsx";
import "../../css/WeblCreation/menu.css";
import PasswordDialog from "./PasswordDialog.jsx";
import DisplaySVG from "../../images/webl_creation_topbar/Display.svg";
import InputSVG from "../../images/webl_creation_topbar/Input.svg";
import setSVG from "../../images/webl_creation_topbar/Set.svg";
import RemoveSVG from "../../images/webl_creation_topbar/Remove.svg";
import SmartSelectSVG from "../../images/webl_creation_topbar/Smart Select_1.svg";
import SetVisibleSVG from "../../images/webl_creation_topbar/Set Visible.svg";
import SetHiddenSVG from "../../images/webl_creation_topbar/Set Hidden.svg";
import MakeAllVisibleSVG from "../../images/webl_creation_topbar/Make all Visible.svg";
import DeselectAllSVG from "../../images/webl_creation_topbar/Deselect All.svg";

function Menu({
	currentSheet,
	spreadsheet,
	setVisibleArea,
	inputs,
	setInputs,
	setHiddenRanges,
	visibleArea,
	charts,
	suggestedVisibleArea,
	hiddenRanges,
	setHighlightSelectedClicked,
	highlightSelectedClicked,
	suggestedInputs,
	updateVisibleArea,
	setSheetManager,
	setRealTimeSwitch,
	realTimeSwitch,
	setCenteredLayout,
	centeredLayout,
	setPersistentChanges,
	peristentChanges,
	setDownloadableSpreadsheet,
	downloadableSpreadsheet,
	setFitWidthSwitch,
	fitWidthSwitch,
	setHideEmptySwitch,
	hideEmptySwitch,
	setOpenAutoselectionDialog,
	spreadsheetRef,
	initialThemeSettings,
	setInitialThemeSettings,
	initialThemeSettingsLabels,
	themeNames,
	tabNames,
	selectedTheme,
	setSelectedTheme,
	passwordProtectEnabled,
	setPasswordDialogIsOpen,
	passwordDialogIsOpen,
	closePasswordDialog,
	passwordToShow,
	setPasswordInputValue,
	setPassword,
	setPasswordProtectEnabled,
	passwordInputValue,
	setPasswordToShow,
	zoomWebl,
	setZoomWebl,
	boxedContainer,
	setBoxedContainer,
	automatedHiddenRanges,
	setAutomatedHiddenRanges,
	automatedVisibleRanges,
	setAutomatedVisibleRanges,
	tabOpenStatus,
	setTabOpenStatus,
	typeOfSelection,
	setTypeOfSelection,
	userType,
	setErrorDialog,
	removeWeblerLogo,
	setRemoveWeblerLogo,
	customScrollbar,
	setCustomScrollbar,
	removeIframeCode,
	setRemoveIframeCode,
	selectedTab,
	setSelectedTab,
}) {
	const [mouseDown, setMouseDown] = useState(false);
	const forbidenIds = [
		"luckysheet-scrollbar-y",
		"luckysheet-sheet-area",
		"luckysheet-scrollbar-x",
		"luckysheet-sheets-rightscroll",
	];

	const handleClick = (e) => {
		let currentNode = e.target;

		if (
			spreadsheetRef.current &&
			spreadsheetRef.current.contains(currentNode) &&
			e.target.id
		) {
			let forbidenId = false;
			while (currentNode) {
				if (forbidenIds.includes(currentNode.id)) {
					forbidenId = true;
					break;
				}
				currentNode = currentNode.parentNode;
			}
			if (!forbidenId) {
				setMouseDown((prevMouseDown) => !prevMouseDown);
			}
		}
	};

	const handleUnclick = (e) => {
		let currentNode = e.target;
		if (
			mouseDown &&
			spreadsheetRef.current &&
			spreadsheetRef.current.contains(e.target)
		) {
			let forbidenId = false;
			while (currentNode) {
				if (forbidenIds.includes(currentNode.id)) {
					forbidenId = true;
					break;
				}
				currentNode = currentNode.parentNode;
			}
			if (!forbidenId) {
				//Here we check if the selected range is already inside a selected Area we remove it from that area. if not we add it.
				//board item: https://app.clickup.com/t/86by65h1m
				// let ranges = spreadsheet.getRange();
				// if (
				// 	JSON.stringify(ranges[0]) ===
				// 	JSON.stringify({ row: [22, 22], column: [52, 52] })
				// ) {
				// 	ranges = ranges.slice(1, ranges.length);
				// }
				// let mustAdd = true;
				// const targetRanges =
				// 	typeOfSelection === "visibleArea"
				// 		? visibleArea[currentSheet]
				// 		: typeOfSelection === "hidden"
				// 		? hiddenRanges[currentSheet]
				// 		: inputs[currentSheet];
				// ranges.forEach((range) => {
				// 	targetRanges.forEach((areaRange) => {
				// 		if (
				// 			range["column"][0] >= areaRange["column"][0] &&
				// 			range["column"][1] <= areaRange["column"][1] &&
				// 			range["row"][0] >= areaRange["row"][0] &&
				// 			range["row"][1] <= areaRange["row"][1]
				// 		) {
				// 			mustAdd = false;
				// 			removeRangeFromElementSelections(
				// 				typeOfSelection,
				// 				areaRange,
				// 				currentSheet,
				// 				spreadsheet,
				// 				visibleArea,
				// 				setVisibleArea,
				// 				inputs,
				// 				setInputs,
				// 				hiddenRanges,
				// 				setHiddenRanges
				// 			);
				// 		}
				// 	});
				// });
				// if (mustAdd)
				markAreaAsElement(
					typeOfSelection,
					currentSheet,
					spreadsheet,
					setVisibleArea,
					inputs,
					setInputs,
					setHiddenRanges,
					visibleArea,
					hiddenRanges,
					charts,
					setHighlightSelectedClicked,
					highlightSelectedClicked,
					currentSheet,
					automatedHiddenRanges,
					setAutomatedHiddenRanges,
					automatedVisibleRanges,
					setAutomatedVisibleRanges
				);
			}
		}

		setMouseDown(false);
	};

	useEffect(() => {
		if (spreadsheet !== undefined && typeOfSelection !== null) {
			document.addEventListener("mousedown", handleClick);
			document.addEventListener("mouseup", handleUnclick);

			return () => {
				document.removeEventListener("mousedown", handleClick);
				document.removeEventListener("mouseup", handleUnclick);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spreadsheet, typeOfSelection, mouseDown]);

	const handleChangeRealTime = () => {
		setRealTimeSwitch(!realTimeSwitch);
	};

	const handleContextAwareCropping = () => {
		setCenteredLayout(!centeredLayout);
	};

	const handlePersistentContentChanges = () => {
		setPersistentChanges(!peristentChanges);
	};

	const handleDownloadableSpreadsheet = () => {
		setDownloadableSpreadsheet(!downloadableSpreadsheet);
	};
	const handleExpandColumnWidth = () => {
		setFitWidthSwitch(!fitWidthSwitch);
	};

	const handleExcludeEmptyHiddenRows = () => {
		setHideEmptySwitch(!hideEmptySwitch);
	};

	const handleOpenUserSettings = () => {
		setOpenAutoselectionDialog(true);
	};
	const handlePasswordProtect = () => {
		//10 chars to just to show that user had previously a password
		setPasswordToShow(passwordProtectEnabled ? "0000000000" : null);
		setPasswordDialogIsOpen(true);
	};

	const handleBoxedContainer = () => {
		setBoxedContainer(!boxedContainer);
	};

	const handleRemoveWeblerLogo = () => {
		setRemoveWeblerLogo(!removeWeblerLogo);
	};

	const handleCustomScrollbar = () => {
		setCustomScrollbar(!customScrollbar);
	};

	const handleRemoveIframe = () => {
		setRemoveIframeCode(!removeIframeCode);
	};

	const handleChangeDisplaySelection = (typeOfSelectionLocal) => {
		const visibleButton =
			document.getElementsByClassName("visible-button")[0];
		const hiddenButton =
			document.getElementsByClassName("hidden-button")[0];
		const inputButton = document.getElementsByClassName("input-button")[0];
		if (typeOfSelectionLocal === "visibleArea") {
			// visibleButton.style.backgroundColor = "#294a5b";
			visibleButton.style.backgroundColor = "#8CC63f";
			hiddenButton.style.backgroundColor = "#395643";
			inputButton.style.backgroundColor = "#395643";
			highlightElementSelections(
				"visibleArea",
				spreadsheet,
				setHighlightSelectedClicked,
				highlightSelectedClicked,
				visibleArea,
				inputs,
				hiddenRanges,
				currentSheet
			);
		} else if (typeOfSelectionLocal === "hidden") {
			hiddenButton.style.backgroundColor = "#8CC63f";
			visibleButton.style.backgroundColor = "#395643";
			inputButton.style.backgroundColor = "#395643";
			highlightElementSelections(
				"hidden",
				spreadsheet,
				setHighlightSelectedClicked,
				highlightSelectedClicked,
				visibleArea,
				inputs,
				hiddenRanges,
				currentSheet
			);
		} else {
			visibleButton.style.backgroundColor = "#395643f";
			hiddenButton.style.backgroundColor = "#395643";
			inputButton.style.backgroundColor = "#8CC63f";
			// highlightElementSelections(
			// 	"input",
			// 	spreadsheet,
			// 	setHighlightSelectedClicked,
			// 	highlightSelectedClicked,
			// 	visibleArea,
			// 	inputs,
			// 	hiddenRanges,
			// 	currentSheet
			// );
		}
		setTypeOfSelection(typeOfSelectionLocal);
	};

	const hideOrUnhideMenu = (e, index) => {
		e.preventDefault();
		const icons = document.getElementsByClassName("basic-button-arrow");
		if (tabOpenStatus[index] === false) {
			setTabOpenStatus(
				new Array(4)
					.fill()
					.map((item, indItem) => (indItem === index ? true : false))
			);

			if (index <= 1) {
				if (index === 0) {
					const visibleButton =
						document.getElementsByClassName("visible-button")[0];
					const hiddenButton =
						document.getElementsByClassName("hidden-button")[0];
					if (
						visibleButton.style.backgroundColor ===
							"rgb(140, 198, 63)" ||
						!visibleButton.style.backgroundColor
					) {
						highlightElementSelections(
							"visibleArea",
							spreadsheet,
							setHighlightSelectedClicked,
							highlightSelectedClicked,
							visibleArea,
							inputs,
							hiddenRanges,
							currentSheet
						);
						setTypeOfSelection("visibleArea");
					} else if (
						hiddenButton.style.backgroundColor ===
						"rgb(140, 198, 63)"
					) {
						highlightElementSelections(
							"hidden",
							spreadsheet,
							setHighlightSelectedClicked,
							highlightSelectedClicked,
							visibleArea,
							inputs,
							hiddenRanges,
							currentSheet
						);
						// const sheet = spreadsheet.getSheet({
						// 	order: currentSheet,
						// });
						// const celldata = sheet["data"];
						// const collength = celldata.length;
						// const rowlength = celldata[0].length;
						setTypeOfSelection("hidden");
					}
				} else if (index === 1) {
					const inputButton =
						document.getElementsByClassName("input-button")[0];
					inputButton.style.backgroundColor = "#8CC63f";
					highlightElementSelections(
						"input",
						spreadsheet,
						setHighlightSelectedClicked,
						highlightSelectedClicked,
						visibleArea,
						inputs,
						hiddenRanges,
						currentSheet
					);
					setTypeOfSelection("input");
				}
				icons[index].style.color = "#8cc63f";
				icons[index].innerHTML = "◀";
				icons[1 - index].style.color = "#ffffff";
				icons[1 - index].innerHTML = "▶";
			} else {
				setTypeOfSelection(null);
				icons[0].style.color = "#ffffff";
				icons[0].innerHTML = "▶";
				icons[1].style.color = "#ffffff";
				icons[1].innerHTML = "▶";
			}
		} else {
			setTabOpenStatus(new Array(4).fill().map(() => false));
			if (index <= 1) {
				setTypeOfSelection(null);
				icons[index].style.color = "#ffffff";
				icons[index].innerHTML = " ▶";
			}
		}
	};
	return (
		<>
			<div className="nav-bar">
				{spreadsheet !== undefined && (
					<Dropdown
						sheetNames={spreadsheet
							.getAllSheets()
							.map((sheet) => sheet["name"])}
						inputs={inputs}
						suggestedInputs={suggestedInputs}
						setInputs={setInputs}
						updateVisibleArea={updateVisibleArea}
						setSheetManager={setSheetManager}
						selectAllElements={selectAllElements}
						spreadsheet={spreadsheet}
						visibleArea={visibleArea}
						charts={charts}
						setVisibleArea={setVisibleArea}
						suggestedVisibleArea={suggestedVisibleArea}
						resetElementSelections={resetElementSelections}
						hiddenRanges={hiddenRanges}
						setHiddenRanges={setHiddenRanges}
						tabNames={tabNames}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				)}
				<button
					className="basic-button"
					onClick={(event) => hideOrUnhideMenu(event, 0)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<img style={{ width: "30px" }} src={DisplaySVG}></img>
					<span style={{ color: "white" }}>Display</span>
					<span className="basic-button-arrow">▶</span>
				</button>
				<ul
					className={
						tabOpenStatus[0]
							? "inner inner-opened"
							: "inner inner-closed"
					}
					style={tabOpenStatus[0] ? { maxWidth: 778.547 } : {}}
				>
					<li>
						<button
							className="visible-button menu-button"
							onClick={() =>
								handleChangeDisplaySelection("visibleArea")
							}
						>
							<img
								className="menu-icon"
								src={SetVisibleSVG}
								alt="Visible Icon"
							/>
							Set Visible
						</button>
					</li>
					<li>
						<button
							className="hidden-button menu-button"
							onClick={() =>
								handleChangeDisplaySelection("hidden")
							}
						>
							<img
								className="menu-icon"
								src={SetHiddenSVG}
								alt="Hidden Area Icon"
							/>
							Set Hidden
						</button>
					</li>
					<li>
						<button
							className="select-button menu-button"
							onClick={() => {
								setVisibleArea(
									selectAllElements(
										"visibleArea",
										currentSheet,
										structuredClone(visibleArea),
										spreadsheet,
										visibleArea,
										suggestedVisibleArea,
										charts,
										inputs
									)
								);
								let visibleAreaCopy = selectAllElements(
									"visibleArea",
									currentSheet,
									structuredClone(visibleArea),
									spreadsheet,
									visibleArea,
									suggestedVisibleArea,
									charts,
									inputs
								);

								let hiddenArea = Array.from(
									{ length: hiddenRanges.length },
									() => []
								);

								let mergedHiddenRanges = [];
								hiddenArea[currentSheet] =
									CheckCellIfVisibleArea(
										hiddenArea[currentSheet],
										spreadsheet,
										visibleAreaCopy,
										currentSheet,
										hiddenRanges
									);
								mergedHiddenRanges = mergeRanges(
									hiddenArea[currentSheet]
								);
								mergedHiddenRanges.forEach((range) => {
									hiddenRanges[currentSheet].push(range);
								});
								setAutomatedHiddenRanges(mergedHiddenRanges);
							}}
						>
							<img
								className="menu-icon"
								src={MakeAllVisibleSVG}
								alt="Select All Icon"
							/>
							Make all visible
						</button>
					</li>
					<li>
						<button
							className="select-button menu-button"
							onClick={() => {
								resetElementSelections(
									visibleArea,
									setVisibleArea,
									currentSheet
								);
								resetElementSelections(
									inputs,
									setInputs,
									currentSheet
								);
								resetElementSelections(
									hiddenRanges,
									setHiddenRanges,
									currentSheet
								);
							}}
						>
							<img
								className="menu-icon"
								src={DeselectAllSVG}
								alt="Deselect All Icon"
							/>
							Clear All
						</button>
					</li>
				</ul>
				<button
					className="basic-button"
					onClick={(event) => hideOrUnhideMenu(event, 1)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<img style={{ width: "30px" }} src={InputSVG}></img>
					<span style={{ color: "white" }}>Inputs</span>
					<span className="basic-button-arrow"> ▶</span>
				</button>
				<ul
					className={
						tabOpenStatus[1]
							? "inner inner-opened"
							: "inner inner-closed"
					}
					style={tabOpenStatus[1] ? { maxWidth: 691.922 } : {}}
				>
					<li>
						<button
							className="select-button menu-button input-button"
							onClick={() => {
								handleChangeDisplaySelection("input");
								setInputs(
									selectAllElements(
										"input",
										currentSheet,
										structuredClone(inputs),
										spreadsheet,
										visibleArea,
										suggestedInputs,
										charts,
										inputs
									)
								);
							}}
						>
							<img
								className="menu-icon"
								src={setSVG}
								alt="Set Icon"
							/>
							Set
						</button>
					</li>
					<li>
						<button
							className="select-button menu-button"
							onClick={() => {
								const inputButton =
									document.getElementsByClassName(
										"input-button"
									)[0];
								inputButton.style.backgroundColor = "#395643";
								let inputsCopy = structuredClone(inputs);
								inputsCopy[currentSheet] = [];
								setInputs(inputsCopy);
							}}
						>
							<img
								className="menu-icon"
								src={RemoveSVG}
								alt="Deselect All Icon"
							/>
							Remove
						</button>
					</li>
					<li>
						<button
							className="select-button highlight-apply menu-button"
							onClick={() => {
								let inputsCopy = structuredClone(inputs);
								spreadsheet.setSheetActive(currentSheet);
								const inputButton =
									document.getElementsByClassName(
										"input-button"
									)[0];
								inputButton.style.backgroundColor = "#395643";

								if (suggestedInputs.length > 0) {
									inputsCopy[currentSheet] =
										suggestedInputs[currentSheet];
								}

								if (
									inputsCopy[currentSheet] !== null &&
									inputsCopy[currentSheet].length > 0
								) {
									spreadsheet.setRangeShow(
										inputsCopy[currentSheet],
										{
											show: true,
										}
									);
									setInputs(inputsCopy);
								}
							}}
						>
							<img
								className="menu-icon"
								src={SmartSelectSVG}
								alt="Apply Suggestions Icon"
							/>
							Smart select
						</button>
					</li>
				</ul>
				<Settings
					realTimeSwitch={realTimeSwitch}
					centeredLayout={centeredLayout}
					peristentChanges={peristentChanges}
					downloadableSpreadsheet={downloadableSpreadsheet}
					fitWidthSwitch={fitWidthSwitch}
					hideEmptySwitch={hideEmptySwitch}
					passwordProtectEnabled={passwordProtectEnabled}
					hideOrUnhideMenu={hideOrUnhideMenu}
					handleChangeRealTime={handleChangeRealTime}
					handleContextAwareCropping={handleContextAwareCropping}
					handlePersistentContentChanges={
						handlePersistentContentChanges
					}
					handleDownloadableSpreadsheet={
						handleDownloadableSpreadsheet
					}
					handleExpandColumnWidth={handleExpandColumnWidth}
					handleExcludeEmptyHiddenRows={handleExcludeEmptyHiddenRows}
					handleOpenUserSettings={handleOpenUserSettings}
					handlePasswordProtect={handlePasswordProtect}
					setZoomWebl={setZoomWebl}
					zoomWebl={zoomWebl}
					handleBoxedContainer={handleBoxedContainer}
					boxedContainer={boxedContainer}
					handleRemoveWeblerLogo={handleRemoveWeblerLogo}
					removeWeblerLogo={removeWeblerLogo}
					handleCustomScrollbar={handleCustomScrollbar}
					customScrollbar={customScrollbar}
					handleRemoveIframe={handleRemoveIframe}
					removeIframeCode={removeIframeCode}
					userType={userType}
					setErrorDialog={setErrorDialog}
				/>
				<Style
					hideOrUnhideMenu={hideOrUnhideMenu}
					initialThemeSettings={initialThemeSettings}
					setInitialThemeSettings={setInitialThemeSettings}
					initialThemeSettingsLabels={initialThemeSettingsLabels}
					themeNames={themeNames}
					tabNames={tabNames}
					chosenTheme={selectedTheme}
					setChosenTheme={setSelectedTheme}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				/>
			</div>
			<PasswordDialog
				open={passwordDialogIsOpen}
				onClose={() => {
					closePasswordDialog();
				}}
				confirmAction={() => {
					closePasswordDialog();
					setPassword(
						document.getElementById("password-input").value
					);

					setTimeout(() => {
						setPasswordProtectEnabled(true);
					}, 500);
				}}
				cancelAction={() => {
					closePasswordDialog();
				}}
				passwordToShow={passwordToShow}
				setPasswordInputValue={setPasswordInputValue}
				passwordProtectEnabled={passwordProtectEnabled}
				closePasswordDialog={closePasswordDialog}
				setPassword={setPassword}
				setPasswordProtectEnabled={setPasswordProtectEnabled}
				passwordInputValue={passwordInputValue}
			/>
		</>
	);
}

export default Menu;
