import React from "react"

export const DomainContext = React.createContext()

export const DomainProvider = ({ children }) => {
  const [domain, setDomain] = React.useState(null)
  const [subdomain, setSubdomain] = React.useState(null)
  const [rootHost, setRootHost] = React.useState(null)

  const fetchDomain = () => {
    const { hostname, port } = window.location;
    let parts = hostname.split('.');
    let updatedHostname;
    setSubdomain(parts.shift()); // remove the subdomain
    setRootHost(`${parts.join('.')}${port ? `:${port}` : ''}`);
    parts.unshift("app");
    updatedHostname = parts.join(".")
    if (parts.includes("localhost")) {
        updatedHostname += port ? `:${port}` : '';
    }
    setDomain(updatedHostname);
  }
  const baseUrl = "//" + domain;

  return (
    <DomainContext.Provider value={{ baseUrl, rootHost, domain, subdomain, fetchDomain }}>
      {children}
    </DomainContext.Provider>
  )
}

export const useDomain = () => React.useContext(DomainContext)
